const siteUrl = process.env.URL || `https://tula.co`;
var accessToken = `${process.env.STORYBLOK_ACCESS_TOKEN}`;

module.exports = {
  siteMetadata: {
    siteUrl: siteUrl,
  },
  plugins: [
    `gatsby-plugin-sass`,
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-typescript`,
      options: {
        isTSX: true, // defaults to false
        jsxPragma: `jsx`, // defaults to "React"
        allExtensions: true, // defaults to false
      },
    },
    `gatsby-plugin-image`,
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: accessToken,
        homeSlug: 'home',
        // version: 'published',   !!! FOR PROUDCTION !!!!
        // version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
        version:
          process.env.NODE_ENV === 'development'
            ? 'draft'
            : process.env.PAGE_STATE === 'draft'
            ? 'draft'
            : 'published',
        // version: 'draft',
      },
    },
    {
      resolve: `gatsby-plugin-env-variables`,
      options: {
        allowList: ['NODE_ENV', 'PAGE_STATE', 'STORYBLOK_ACCESS_TOKEN'],
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /assets/,
        },
      },
    },
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        // Defaults used for gatsbyImageData and StaticImage
        defaults: {},
        // Set to false to allow builds to continue on image errors
        failOnError: true,
        // deprecated options and their defaults:
        base64Width: 20,
        // forceBase64Format: `[png, jpg, webp]`, // valid formats: png,jpg,webp
        useMozJpeg: process.env.GATSBY_JPEG_ENCODER === `MOZJPEG`,
        stripMetadata: true,
        defaultQuality: 100,
      },
    },
    'gatsby-plugin-postcss',
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `TulaCo`,
        short_name: `TulaCo`,
        lang: `en`,
        icon: `src/images/icon.png`,
      },
    },
    {
      resolve: 'gatsby-plugin-sitemap',
      options: {
        entryLimit: 5000,
        excludes: [
          '/global',
          '/shared',
          '/home',
          '/caseStudy/',
          '/privacy-policy',
          '/blogPost',
          '/blog/template*',
          // '/authors/*',
          // '/authors',
          '/blog/\\[\\.\\.\\.\\]',
          '/author',
        ],
        output: '/',
      },
    },
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: siteUrl,
        sitemap: `${siteUrl}/sitemap-index.xml`,
        policy: [{ userAgent: '*', allow: '/', disallow: ['/home/'] }],
      },
    },
    {
      resolve: 'gatsby-plugin-react-leaflet',
      options: {
        linkStyles: false, // (default: true) Enable/disable loading stylesheets via CDN
      },
    },
    {
      resolve: `gatsby-plugin-purgecss`,
      options: {
        // printRejected: true,
        tailwind: true,
        ignore: [
          '/src/components/',
          '/src/sb-components/',
          '/node_modules/leaflet/',
          '/node_modules/filepond/',
          '/node_modules/@storyblok/',
        ],
        purgeCSSOptions: {
          safelist: ['/^button/'],
        },
      },
    },
    `gatsby-plugin-force-trailing-slashes`,
  ],
};
