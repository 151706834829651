import React from 'react';

export enum ScrollDirection {
  'down',
  'up',
}

export interface IContext {
  fullSlug: string | undefined;
  pageOffset: number;
  scrollDirection: ScrollDirection;
  screen: {
    width: number;
    height: number;
    isMobile: boolean;
    isTablet: boolean;
  };
}

const PageContext = React.createContext<Partial<IContext>>({});

export default PageContext;
