import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { SeparatorStoryblok } from '../../types';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import './separator.component.scss';

const SbSeparator: ReactDynamicComponent<SeparatorStoryblok> = ({ blok }) => (
  <div {...storyblokEditable(blok)} className="separator-wrapper">
    {blok.image &&
      blok.image[0] &&
      blok.image[0].image?.title !== 'scale_elements' && (
        <AdaptiveImage
          className="separator-image"
          src={blok.image[0].image?.filename || ''}
          processing=""
          alt={blok.image && blok.image[0].image?.alt}
        />
      )}
    {blok.image &&
      blok.image[0] &&
      blok.image[0].image?.title === 'scale_elements' && (
        <AdaptiveImage
          width="800px"
          className="separator-image separator-scale"
          src={blok.image[0].image?.filename || ''}
          processing=""
          alt={blok.image && blok.image[0].image?.alt}
        />
      )}
  </div>
);
export default SbSeparator;
