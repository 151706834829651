import React, { useContext, useEffect, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import PageContext, { ScrollDirection } from '../pageContext';

import { HeaderStoryblok, LinkItemStoryblok } from '../../types';

import { ReactDynamicComponent } from '../common';
import Link from '../../components/common/link/link.component';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

import MenuIcon from '../../assets/images/menu.svg';
import SubMenuArrow from '../../assets/images/subMenuArrow.svg';
import TulaLogo from '../../assets/images/TulaLogo.svg';
import Show from '../../assets/images/show.svg';
import Hide from '../../assets/images/hide.svg';

import './header.component.scss';

const SbHeaderComponent: ReactDynamicComponent<HeaderStoryblok> = ({
  blok,
}) => {
  const menuHideTreshold = 200; // offset from top when menu may hide
  const parseLink = (link: string) => link.replace(/(.*?)\/*$/, '$1');
  const getButtonVisibility = (slug: string, offset: number) =>
    offset > 450 || parseLink(slug) !== 'home';

  const {
    fullSlug = '',
    pageOffset = 0,
    scrollDirection,
  } = useContext(PageContext);
  const [currentOffset, setCurrentOffset] = useState<number>(0);
  const [buttonIsVisible, setButtonVisibility] = useState<boolean>(
    getButtonVisibility(fullSlug, pageOffset)
  );
  const [menuIsOpened, setMenuOpenStatus] = useState<boolean>(false);
  const [hoverOpen, setHoverOpen] = useState(false);
  const [hoverMobileOpen, setHoverMobileOpen] = useState(false);

  useEffect(() => {
    setButtonVisibility(getButtonVisibility(fullSlug, pageOffset));
    if (menuIsOpened) {
      window.scrollTo(0, currentOffset);
    }
  }, [pageOffset]);

  const clickMenuHandler = () => {
    setMenuOpenStatus(!menuIsOpened);
    setCurrentOffset(pageOffset);
  };
  const solutionsMenuOpen = () => {
    setHoverOpen(true);
  };
  const solutionsMobileOpen = () => {
    setHoverMobileOpen(!hoverMobileOpen);
  };
  const solutionsMenuClose = () => {
    setHoverOpen(false);
  };
  const menuItem = (item: LinkItemStoryblok, index: number) => {
    const active = parseLink(item.link?.cached_url) === parseLink(fullSlug);
    const activeClass = active
      ? 'header__item-border active'
      : 'border-transparent';

    return (
      <Link
        link={item.link}
        className={[
          'header__item pl-14 md:pl-0 py-3 md:py-5 lg:py-7 md:mx-5 lg:mx-4 Poppins-24-36-Bold md:Poppins-14-20-Medium lg:Poppins-16-24-SemiBold md:border-t-4',
          activeClass,
        ].join(' ')}
        key={index}
        solutionsMenuOpen={solutionsMenuOpen}
        solutionsMenuClose={solutionsMenuClose}
      >
        {item.title}
        <div className="first">
          {hoverMobileOpen &&
            menuIsOpened &&
            item.subItems?.map((it: LinkItemStoryblok) => (
              <Link
                link={it.link}
                className={[
                  'header__item sub-items-link pl-14 md:pl-0 py-3 md:py-5 lg:py-7 md:mx-5 lg:mx-4 md:border-t-4',
                  parseLink(it.link?.cached_url) === parseLink(fullSlug)
                    ? 'header__item-border active'
                    : 'border-transparent',
                ].join(' ')}
                key={index}
              >
                {it.title}
              </Link>
            ))}
        </div>
      </Link>
    );
  };
  return (
    <header
      className={`header text-blue top-0 z-50 p-4 md:p-0 ${
        pageOffset > 0 && !menuIsOpened
          ? 'header__home-page-lower-position'
          : 'header__home-page-top-position'
      } ${menuIsOpened ? 'menu-opened' : ''} ${
        pageOffset > menuHideTreshold &&
        !menuIsOpened &&
        scrollDirection === ScrollDirection.down
          ? 'header__away'
          : ''
      }`}
      {...storyblokEditable(blok)}
    >
      <div
        className={`container flex justify-between items-center text-blue z-10 p-4 md:p-0 ${
          menuIsOpened ? 'menu-opened' : ''
        }`}
      >
        <a href="/" className="header__logo" aria-label="TULACO website">
          <img
            src={blok.logo?.filename}
            alt={blok.logo?.alt}
            className="max-h-10"
          />
        </a>
        <div className="header__menu">
          <div
            className="header__menu-toggler pt-1 md:hidden text-right"
            onClick={clickMenuHandler}
          >
            <div className="header__menu-open flex pr-4 items-center">
              <span className="Poppins-14-20-Medium header__menu-caption">
                Menu
              </span>

              <MenuIcon className="w-4 h-4 cursor-pointer" />
            </div>

            <span className="header__menu-close text-4xl pr-6">
              {' '}
              <TulaLogo className="pl-6 mb-1" /> &times;
            </span>
          </div>
          <div className="header__menu-content md:flex items-center hidden md:block">
            <div className="showMenuSolutions" onClick={solutionsMobileOpen}>
              {hoverMobileOpen ? <Hide /> : <Show />}
            </div>
            {blok.menu &&
              blok.menu[0].items.map((item: LinkItemStoryblok, index: number) =>
                menuItem(item, index)
              )}
            {hoverOpen && !menuIsOpened && (
              <div
                className="header__menu-solutions"
                onMouseEnter={() => solutionsMenuOpen()}
                onMouseLeave={() => solutionsMenuClose()}
              >
                <div className="subMenu-block">
                  <SubMenuArrow className="bkgr-subMenu" />
                  <div className="subMenu-items">
                    {blok.menu &&
                      blok.menu[0].items[0].subItems.map(
                        (item: LinkItemStoryblok, index: number) =>
                          menuItem(item, index)
                      )}
                  </div>
                </div>
              </div>
            )}
            <div
              className={`header__button-wrapper Poppins-24-36-Bold pl-14 md:pl-0 lg:ml-4 ${
                buttonIsVisible ? 'btn-visible' : 'btn-hidden'
              }`}
            >
              {dynamicComponentsBlock(blok.button)}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default SbHeaderComponent;
