import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ImageLinkItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import './image-link-item.component.scss';

const SbImageLinkItem: ReactDynamicComponent<ImageLinkItemStoryblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)}>
    <div>
      {blok.link?.cached_url ? (
        <a
          className="image-link"
          href={blok.link.cached_url}
          title={blok.title}
          target="_blank"
          rel="noreferrer"
        >
          <img src={blok.image?.filename} alt={blok.title} />
        </a>
      ) : (
        <img src={blok.image?.filename} alt={blok.title} />
      )}
    </div>
  </div>
);

export default SbImageLinkItem;
