import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import Slider from 'react-slick';

import { ImageGalleryStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import Right from '../../assets/images/Right.svg';
import Left from '../../assets/images/Left.svg';

import './imageGallery.component.scss';

const SbImageGalleryComponent: ReactDynamicComponent<ImageGalleryStoryblok> = ({
  blok,
}) => {
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);

  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <div className="hidden lg:block absolute left-2/3 top-full transform">
        <Right
          className={`max-h-8 arrow w-8 h-8 p-2.5 rounded-full cursor-pointer ml-2 ${
            isLast ? 'arrow-light' : ''
          }`}
          onClick={onClick}
        />
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <div className="hidden lg:block absolute left-2/3 top-full transform -translate-x-10">
        <Left
          className={`arrow w-8 h-8 p-2.5 rounded-full cursor-pointer mr-2 ${
            isFirst ? 'arrow-light' : ''
          }`}
          onClick={onClick}
        />
      </div>
    );
  }

  const settings = {
    slidesToShow: 4,
    swipeToSlide: true,
    dots: false,
    touchThreshold: 20,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    beforeChange: (beforeChange: number, next: number) => {
      setIsFirst(next === 0);
      setIsLast(
        blok.items ? blok.items?.length === next + settings.slidesToShow : false
      );
    },
  };

  return (
    <div {...storyblokEditable(blok)}>
      <div className="image-gallery pb-10">
        <Slider {...settings} className="md:pb-5">
          {blok.items?.map((item) => dynamicComponentsBlock(item))}
        </Slider>
      </div>
    </div>
  );
};

export default SbImageGalleryComponent;
