import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { ImageGallerySimpleStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './imageGallerySimple.component.scss';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbImageGallerySimpleComponent: ReactDynamicComponent<ImageGallerySimpleStoryblok> =
  ({ blok }) => {
    const classes =
      blok.images && blok.images[0].description
        ? 'grid grid-cols-3 md:grid-cols-8 gap-y-6 place-items-center image-gallery'
        : 'grid md:grid-cols-6 overflow-x-auto gallery-grid mt-11 lg:mt-16 image-gallery';

    return (
      <div {...storyblokEditable(blok)}>
        <div className={classes}>
          {blok.images?.map((item) => (
            <div
              key={item._uid}
              className={`${
                item.description
                  ? 'image-gallery__gallery-item-small'
                  : 'image-gallery__gallery-item'
              } mb-3 lg:mb-6`}
            >
              {item.image?.map(
                (image) =>
                  image.image && (
                    <div className="image-gallery__item" key={image._uid}>
                      <div>
                        <AdaptiveImage
                          src={image.image.filename || ''}
                          alt={image.image.alt || ''}
                          processing={image.processing}
                        />
                      </div>
                      <div className="text-grey-2 Poppins-14-20-Regular text-center mt-1 whitespace-nowrap">
                        {item.description}
                      </div>
                    </div>
                  )
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };

export default SbImageGallerySimpleComponent;
