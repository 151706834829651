import React, { useContext } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { JobOpeningsStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import SimpleSliderComponent from '../../components/common/simpleSlider/simpleSlider.component';
import PageContext from '../pageContext';
import './jobOpenings.component.scss';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

import Caret1 from '../../assets/images/caret01.svg';
import Caret2 from '../../assets/images/caret02.svg';

const SbJobOpenings: ReactDynamicComponent<JobOpeningsStoryblok> = ({
  blok,
}) => {
  const { label1Header, label1, label2, Images, button1, button2 } = blok;

  const { screen } = useContext(PageContext);

  const Background = () =>
    screen?.isMobile || screen?.isTablet ? (
      <div className="job-openings__bg">
        <Caret1 className="job-openings__bg-caret job-openings__bg-caret_1" />
        <Caret2 className="job-openings__bg-caret job-openings__bg-caret_2" />
      </div>
    ) : null;

  return (
    <div className="job-openings" {...storyblokEditable(blok)}>
      <div className="lg:w-full lg:w-7/12 lg:order-2 relative lg:pt-24">
        <div className="job-openings__slider-wrapper">
          <SimpleSliderComponent images={Images} />
        </div>
        <div className="absolute lg:static top-0 bottom-0 left-0 right-0 p-6 lg:p-0 job-openings__text-wrapper">
          <div className="Poppins-16-24-Regular mt-4 mb-4 hidden lg:block">
            {label2}
          </div>
          <div className="job-openings__slider-text">
            <div className="lg:hidden job-openings__slider-title">
              <span className="text-shadow">We’re TulaCo</span>
              <div className="red-line" />
            </div>
            <div className="job-openings__link-wrapper">
              {dynamicComponentsBlock(button2)}
            </div>
          </div>
        </div>
      </div>
      <div className="p-6 lg:p-0 md:w-full w-5/12 bg-gray-50 lg:bg-transparent rounded-lg flex flex-col relative">
        <Background />
        <div className="job-openings__header-1 mb-3">{label1Header}</div>
        <div className="Poppins-16-24-Regular mb-4">{label1}</div>
        <div className="job-openings__stats">
          {dynamicComponentsBlock(button1)}
        </div>
      </div>
    </div>
  );
};

export default SbJobOpenings;
