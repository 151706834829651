import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { generatedId } from '../../utils/uidGenerator';
import { ReactDynamicComponent } from '../common';
import ArrowDown from '../../assets/images/arrow-down.svg';
import {
  TableOfContentsItemStoryblok,
  TableOfContentsStoryblok,
} from '../../types';

import './table-of-contents.component.scss';

const clickLink = (e: React.MouseEvent, elem: TableOfContentsItemStoryblok) => {
  e.preventDefault();
  const el = document.querySelector(`#${elem?.anchorLink?.anchor}`);
  el?.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });

  setTimeout(() => {
    window.scrollBy(0, 1);
  }, 1000);
};

const SbTableOfContentComponent: ReactDynamicComponent<TableOfContentsStoryblok> =
  ({ blok }) => (
    <div {...storyblokEditable(blok)}>
      <div className="table-of-content">
        {blok.items?.map((elem) => (
          <div className="table-of-content__link-wrapper">
            <a
              className="table-of-content__link"
              href={elem?.anchorLink?.anchor}
              key={generatedId()}
              onClick={(e) => clickLink(e, elem)}
            >
              {elem.title}
            </a>
            <ArrowDown className="table-of-content__link-arrow" />
          </div>
        ))}
      </div>
    </div>
  );

export default SbTableOfContentComponent;
