import React from 'react';
import BannerBgImg from '../../assets/images/BannerBg.svg';

import './banner-bg.component.scss';

const BannerBg = () => (
  <div className="banner_bg h-screen-4/5">
    <BannerBgImg />
  </div>
);

export default BannerBg;
