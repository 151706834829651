import React, { useEffect, useRef, useState, useContext } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { SocialGaleryStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import PageContext from '../pageContext';
import './social-gallery.component.scss';
import StoryblokService from '../../utils/storyblok-service';

const SbSocialGallery: ReactDynamicComponent<SocialGaleryStoryblok> = ({
  blok,
}) => {
  enum Status {
    stopped,
    waiting,
    startSlider,
    stepProcess,
    stepFinish,
    slidesShift,
    slidesShifted,
  }

  const { title, label, items, labelLink, labelText } = blok;
  const { screen } = useContext(PageContext);
  const gallery = useRef() as React.RefObject<HTMLDivElement>;

  const [animationEnabled, animationEnable] = useState(false);
  const [slides, setSlides] = useState(items);
  const [status, setStatus] = useState(Status.stopped);
  const [offset, setOffset] = useState(0);

  const intersectionObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };

  const slideDuration = 4500; // in ms

  const intersectionObserverCallback = (
    entries: { isIntersecting: boolean }[]
  ) => {
    if (entries[0].isIntersecting) {
      animationEnable(true);
    }
  };

  const getOffset = (element: HTMLDivElement | null) => {
    if (element) {
      const children = element.children[0] as HTMLDivElement;
      const tempOffset = children.offsetLeft + children.offsetWidth;
      setOffset(tempOffset);
    }
  };

  useEffect(() => {
    // get newest page content and find gallery items
    const fetchData = async () => {
      const request = await StoryblokService.get(`cdn/stories/careers/`);
      const newItems = request?.data?.story?.content?.body?.find(
        (a: any) => a.component === 'socialGalery'
      ).items;
      if (newItems && newItems.length) {
        setSlides(newItems);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (animationEnabled) {
      return;
    }

    const observer = new IntersectionObserver(
      intersectionObserverCallback,
      intersectionObserverOptions
    );

    if (gallery.current) {
      observer.observe(gallery.current);
    }

    getOffset(gallery.current);
  }, [gallery.current]);

  useEffect(() => {
    const { current } = gallery;
    if (!current) {
      return;
    }

    switch (status) {
      case Status.waiting:
        setTimeout(() => {
          setStatus(Status.startSlider);
        }, 50);
        break;
      case Status.startSlider:
        current.style.transition = `transform ${slideDuration}ms`;
        current.style.transform = `translate(-${offset}px,0)`;
        setStatus(Status.stepProcess);
        break;
      case Status.stepProcess:
        setTimeout(() => {
          setStatus(Status.stepFinish);
        }, slideDuration);
        break;
      case Status.stepFinish:
        current.style.transition = '';
        setTimeout(() => {
          setStatus(Status.slidesShift);
        }, 50);
        break;
      case Status.slidesShift:
        if (slides) {
          const arr = [...slides];
          const buf = arr.shift();
          if (buf) {
            arr.push(buf);
          }
          setStatus(Status.slidesShifted);
          setSlides(arr);
          current.style.transform = 'translate(0,0)';
        }
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (status === Status.slidesShifted) {
      setStatus(Status.waiting);
    }
  }, [slides]);

  useEffect(() => {
    if (animationEnabled) {
      setStatus(Status.waiting);
    }
  }, [animationEnabled]);

  useEffect(() => {
    getOffset(gallery.current);
  }, [screen]);

  return (
    <div {...storyblokEditable(blok)}>
      <div className="social-gallery wide">
        <h3 className="social-gallery__title">{title}</h3>
        <p className="social-gallery__label">
          {label}{' '}
          <a href={labelLink?.cached_url} target="_blank" rel="noreferrer">
            {labelText}
          </a>{' '}
        </p>
        <div className="social-gallery__items-wrapper">
          <div
            className="social-gallery__items-holder"
            ref={gallery}
            data-status={status}
          >
            {slides?.map((item) => (
              <div
                className="social-gallery__item-wrapper"
                key={item._uid}
                data-key={item._uid}
              >
                {dynamicComponentsBlock(item)}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbSocialGallery;
