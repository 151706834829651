import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import PageContext from '../pageContext';
import BlogContext from '../blogContext';
import { generatedId } from '../../utils/uidGenerator';

import AdaptiveImage from '../../components/image/adaptiveImage.component';
import DynamicComponent from '../dynamicComponent';
import Link from '../../components/common/link/link.component';

import Facebook from '../../assets/images/fb.svg';
import LinkedIn from '../../assets/images/linkedin.svg';
import Left from '../../assets/images/Left.svg';
import Copy from '../../assets/images/copy.svg';

import './postMainContent.component.scss';
import { PostStoryblok } from '../../types';

const SbPostItem: ReactDynamicComponent<PostStoryblok> = ({ blok }) => {
  const { pageOffset = 0, screen } = useContext(PageContext);
  const [contentHeight, setContentHeight] = useState(1);
  const { title, content, author, readTime } = blok;
  const { authors } = useContext(BlogContext);
  const [TableOfContent, setTableOfContent] = useState<any>([]);
  const [showText, setShowText] = useState<any>(false);
  const target = useRef() as React.RefObject<HTMLDivElement>;

  const modifiedContent = content.map((item: any) => ({ ...item, target }));

  const progressBar = () => {
    let scrollPercentage = (pageOffset / contentHeight) * 100;

    if (scrollPercentage === Infinity) {
      scrollPercentage = 1;
    }

    if (scrollPercentage > 100) {
      scrollPercentage = 100;
    }

    return scrollPercentage.toFixed(2);
  };

  useEffect(() => {
    const mainContent =
      document.getElementById('post-main-content')?.clientHeight;
    const maxScroll = mainContent ? mainContent - window.innerHeight : 0;

    setContentHeight(maxScroll);

    progressBar();
  }, [pageOffset]);

  useEffect(() => {
    const newTableOfContent = modifiedContent
      ?.filter(
        (a: any) =>
          (a.component === 'commonText' || 'textBlock' || 'textBlock2') &&
          a.tableOfContentItem
      )
      ?.filter((a: any) => a.id)
      ?.filter((a: any) => document.getElementById(`${a.id}`))
      ?.map((item: any) => {
        const elem = document.getElementById(`${item.id}`);
        return {
          offset: elem?.offsetTop,
          title: item.tableOfContentItem[0]?.title,
          anchor: item.tableOfContentItem[0]?.anchorLink,
        };
      });
    setTimeout(() => {
      setTableOfContent(newTableOfContent);
    }, 0);
  }, [
    modifiedContent.map((item: any) => item.target.current).toString(),
    screen?.width,
    screen?.height,
  ]);

  const linkData = {
    cached_url: 'blog',
    linktype: 'story',
  };

  const isBrowser = typeof window !== 'undefined';

  const renderTableOfContent = () => {
    let fired = false;
    return TableOfContent.slice(0)
      .reverse()
      .map((item: any) => {
        const active = !fired && item.offset < pageOffset;
        if (active) {
          fired = true;
        }
        const { anchor } = item;
        const anchorValue = typeof anchor === 'string' ? anchor : anchor.anchor;

        return (
          <a
            className={`post-page-component__table-of-contents-item ${
              active ? 'active' : ''
            }`}
            href={`#${anchorValue}`}
            key={generatedId()}
            onClick={(e) => {
              e.preventDefault();

              const el = document.querySelector(`#${anchorValue}`);
              el?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });

              setTimeout(() => {
                window.scrollBy(0, 1);
              }, 1000);
            }}
          >
            {item.title}
          </a>
        );
      })
      .reverse();
  };

  const renderContent = () => (
    <div className="post-page-component__content">
      {modifiedContent?.map((contentItem: any) => {
        const componentType = contentItem.component;

        if (componentType === 'image') {
          return (
            <AdaptiveImage
              className="post-page-component__content-item"
              src={contentItem.image?.filename || ''}
              mobilesrc={
                contentItem.mobileImage?.filename ||
                contentItem.image?.filename ||
                ''
              }
              alt={contentItem.image.alt}
              processing={contentItem.processing}
              width={contentItem.width}
              key={String(contentItem._uid)}
            />
          );
        }

        return (
          <div
            className="post-page-component__content-item"
            ref={contentItem.target}
            id={contentItem.id}
            key={String(contentItem._uid)}
          >
            <DynamicComponent blok={contentItem} />
          </div>
        );
      })}
    </div>
  );

  const copyToClipboard = (string: string) => {
    let textarea;
    let result;

    try {
      textarea = document.createElement('textarea');
      textarea.setAttribute('readonly', 'true');
      textarea.setAttribute('contenteditable', 'true');
      textarea.style.position = 'fixed'; // prevent scroll from jumping to the bottom when focus is set.
      textarea.value = string;

      document.body.appendChild(textarea);

      textarea.focus();
      textarea.select();

      const range = document.createRange();
      range.selectNodeContents(textarea);

      const sel = window.getSelection();
      sel?.removeAllRanges();
      sel?.addRange(range);

      textarea.setSelectionRange(0, textarea.value?.length);
      result = document.execCommand('copy');

      setShowText(true);
      setTimeout(() => {
        setShowText(false);
      }, 1500);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      result = null;
    } finally {
      document.body.removeChild(textarea as Node);
    }

    // manual copy fallback using prompt
    if (!result) {
      const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
      result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
      if (!result) {
        return false;
      }
    }
    return true;
  };

  const renderSocialIcons = (isMobile = false) => (
    <div
      className={
        isMobile
          ? 'post-page-component__social-mobile'
          : 'post-page-component__social'
      }
    >
      {isMobile ? (
        <div className="post-page-component__social-share-text Poppins-12-20-Medium">
          SHARE:
        </div>
      ) : null}

      <div className="post-page-component__social-icon post-page-component__social-icon_fb">
        <FacebookShareButton
          url={isBrowser ? window.location.href : ''}
          data-class="post-page-component__social-icon-tooltip"
          data-arrow-color="white"
          data-tip="SHARE ON FACEBOOK"
        >
          <Facebook />
          <ReactTooltip className="social-icon-tooltip" />
        </FacebookShareButton>
      </div>
      <div className="post-page-component__social-icon post-page-component__social-icon_linked">
        <LinkedinShareButton
          data-class="post-page-component__social-icon-tooltip"
          data-arrow-color="white"
          data-tip="SHARE ON LINKEDIN"
          url={isBrowser ? window.location.href : ''}
        >
          <LinkedIn />
          <ReactTooltip />
        </LinkedinShareButton>
      </div>
      <div
        className={`post-page-component__social-icon post-page-component__social-icon_copy${
          showText ? ' clicked' : ''
        }`}
        onClick={() => (isBrowser ? copyToClipboard(window.location.href) : {})}
        data-class="post-page-component__social-icon-tooltip"
        data-arrow-color="white"
        data-tip="COPY"
      >
        <Copy />
        <ReactTooltip />
        <div
          className="post-page-component__social-icon-description"
          style={{ opacity: showText ? '1' : '0', transition: 'opacity 1s' }}
        >
          COPIED
        </div>
      </div>
    </div>
  );

  const renderAuthorBlock = () => {
    const authorInfo = author && authors[author].content.author[0];

    return (
      <div className="post-page-component__author">
        <div className="post-page-component__author-info">
          <div className="post-page-component__author-photo">
            {authorInfo.shortImage && authorInfo.shortImage[0] && (
              <AdaptiveImage
                src={authorInfo.shortImage[0].image.filename}
                mobilesrc={authorInfo.shortImage[0].mobileImage.filename}
                alt={authorInfo.shortImage[0].image.alt}
                processing={authorInfo.shortImage[0].processing}
              />
            )}
          </div>
          <div>
            <div className="post-page-component__author-name Poppins-18-28-Bold">
              {authorInfo.name}
            </div>
            <div className="post-page-component__author-position Poppins-14-20-Medium">
              {authorInfo.jobPosition}
            </div>
          </div>
        </div>
        <div className="post-page-component__author-description">
          {authorInfo.description}
        </div>
        <Link
          link={{
            cached_url: authors[author].full_slug,
            linktype: 'story',
          }}
          className="post-page-component__author-link Poppins-18-28-Bold"
        >
          Read more from this author
        </Link>
      </div>
    );
  };

  return (
    <div {...storyblokEditable(blok)}>
      <div className="post-page-component container">
        <div className="post-page-component__page-content-wrapper">
          <div className="post-page-component__back-link Poppins-16-24-Bold">
            <Link link={linkData}>
              <Left />
              Back to all blogposts
            </Link>
          </div>

          <div className="post-page-component__wrapper" id="post-main-content">
            <div className="post-page-component__description-container">
              <div className="post-page-component__description">
                <div className="post-page-component__read-time Poppins-12-20-Medium">
                  &bull; {readTime} min read
                </div>
                <div className="post-page-component__progress-bar-container">
                  <div
                    className="post-page-component__progress-bar"
                    style={{ width: `${progressBar()}%` }}
                  />
                </div>
                <div className="post-page-component__table-of-contents Poppins-14-20-Regular">
                  {renderTableOfContent()}
                </div>
                {renderSocialIcons()}
              </div>
            </div>

            <div className="post-page-component__content-wrapper">
              <h1 className="post-page-component__title">{title}</h1>
              {renderContent()}
              {renderSocialIcons(true)}
              {renderAuthorBlock()}
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default SbPostItem;
