import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { CultureItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

const SbCultureItem: ReactDynamicComponent<CultureItemStoryblok> = ({
  blok,
}) => {
  const { benefit } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      {benefit?.length
        ? benefit.map((item) => (
            <div key={item._uid}>{dynamicComponentsBlock(item)}</div>
          ))
        : null}
    </div>
  );
};

export default SbCultureItem;
