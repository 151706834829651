import React, { FC, useEffect, useRef, useState } from 'react';

import { ImageStoryblok } from '../../../types';
import './simpleSlider.component.scss';
import AdaptiveImage from '../../image/adaptiveImage.component';

interface ISlider {
  images?: ImageStoryblok[];
}

interface ISlideData {
  src: string | undefined;
  mobilesrc: string | undefined;
  tabletsrc: string | undefined;
  alt: string | undefined;
  title?: string | undefined;
  processing?: string | undefined;
}

const SimpleSliderComponent: FC<ISlider> = ({ images }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideData, setSlideData] = useState<ISlideData>();
  const [animate, setAnimate] = useState(false);
  const timeoutRef: any = useRef(null);

  const getNewSlide = () => {
    const currentSlide = images && images[slideIndex];
    setSlideData({
      src: currentSlide?.image?.filename,
      mobilesrc: currentSlide?.mobileImage?.filename,
      tabletsrc: currentSlide?.tabletImage?.filename,
      alt: currentSlide?.image?.alt,
      title: currentSlide?.image?.title,
      processing: currentSlide?.processing,
    });
  };

  const flipSlide = () => {
    setAnimate(true);
    const timeoutId = setTimeout(() => {
      if (images) {
        setSlideIndex((slideIndex + 1) % images.length);
      }
      setAnimate(false);
    }, 800);
    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    getNewSlide();
    if (images && images.length > 1) {
      timeoutRef.current = setTimeout(() => {
        flipSlide();
      }, 6000);
      return () => clearTimeout(timeoutRef.current);
    }
  }, [slideIndex]);

  return (
    <div
      className={`simple-slider${
        animate ? ' simple-slider_animate' : ''
      } w-full`}
    >
      <div className="simple-slider__wrapper">
        <AdaptiveImage
          className="w-full h-full object-contain"
          src={slideData?.src || ''}
          mobilesrc={slideData?.mobilesrc || ''}
          tabletsrc={slideData?.tabletsrc || ''}
          alt={slideData?.alt || ''}
          title={slideData?.title || ''}
          processing={slideData?.processing}
        />
      </div>
    </div>
  );
};

export default SimpleSliderComponent;
