import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer-ts';
import { ReactDynamicComponent } from '../common';
import { CodeSnippetStoryblok } from '../../types';

import './codeSnippetCustom.component.scss';

const SbCodeSnippetCustom: ReactDynamicComponent<CodeSnippetStoryblok> = ({
  blok,
}) => {
  const { code, backgroundColor, textColor } = blok;

  return (
    <div {...storyblokEditable(blok)} className="code-block">
      <pre
        style={{
          background: backgroundColor,
          color: textColor,
        }}
      >
        {render(code)}
      </pre>
    </div>
  );
};

export default SbCodeSnippetCustom;
