import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ValuesStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import Plus from '../../assets/images/plus.svg';

import './values.component.scss';

const SbValuesBlock: ReactDynamicComponent<ValuesStoryblok> = ({ blok }) => {
  const { Items, Title } = blok;

  const [activeItems, setActiveItems] = useState([]);

  const renderValuesItems = () => {
    const onItemClick = (index: never) => {
      if (activeItems.indexOf(index) > -1) {
        setActiveItems(activeItems.filter((item) => item !== index));
      } else {
        setActiveItems([...activeItems, index]);
      }
    };

    return Items?.map((item, index) => {
      const isActive = activeItems.includes(index as never);

      return (
        <div className="values__item" key={String(index)}>
          <div className="values__item-body">
            <div className="values__item-left">
              <div className="values__item-red-rectangle" />
              <div className="values__item-title Poppins-16-24-SemiBold md:Poppins-24-36-Bold">
                {item.title}
              </div>
            </div>

            <div
              className={`values__item-button${
                isActive ? ' values__item-button_active' : ''
              }`}
              onClick={() => onItemClick(index as never)}
            >
              <Plus />
            </div>
          </div>

          {isActive ? (
            <div className="values__item-description Lora-18-30-Regular">
              {item.label}
            </div>
          ) : null}
        </div>
      );
    });
  };

  return (
    <div {...storyblokEditable(blok)}>
      <div className="values container">
        <div className="values__title Poppins-28-36-Bold md:Poppins-42-56-Bold">
          {Title}
        </div>
        <div className="values__items">{renderValuesItems()}</div>
      </div>
    </div>
  );
};

export default SbValuesBlock;
