import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { FormSectionStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

const SbFormSection: ReactDynamicComponent<FormSectionStoryblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)} className="form-section">
    {dynamicComponentsBlock(blok.fields)}
  </div>
);

export default SbFormSection;
