import React, { FC } from 'react';
import NoImageIcon from '../../assets/images/noimage.svg';

import './noImage.scss';

const NoImage: FC = () => (
  <div className="no-image-component">
    <div className="no-image-component__content">
      <NoImageIcon />
      <div className="no-image-component__title">Image not found</div>
    </div>
  </div>
);

export default NoImage;
