import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { CallToActionBlockStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import './callToActionBlock.component.scss';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

const SbCallAction: ReactDynamicComponent<CallToActionBlockStoryblok> = ({
  blok,
}) => {
  const { header } = blok;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className="call-action">
        <div className="header-wrapper">
          <h2 className="header-action">{header}</h2>
          <div className="btn">{dynamicComponentsBlock(blok.button)}</div>
        </div>
      </div>
    </div>
  );
};
export default SbCallAction;
