import { Player } from '@lottiefiles/react-lottie-player';
import React, { FC, useState } from 'react';
import { AnimatedImageStoryblok } from '../../types';

const SbAnimatedImageComponent: FC<AnimatedImageStoryblok> = ({
  src,
  width = '70px',
  height = '70px',
  isSelectedBenefit,
  processing,
  _uid,
}) => {
  const [lottie, setLottie] = useState<any>(null);
  if (isSelectedBenefit.value && isSelectedBenefit.uid === _uid) {
    lottie?.setDirection(1);
    lottie?.play();
  } else if (!isSelectedBenefit.value && isSelectedBenefit.uid === _uid) {
    lottie?.setDirection(-1);
    lottie?.play();
  }

  return (
    <Player
      lottieRef={(instance) => {
        setLottie(instance);
      }}
      keepLastFrame
      speed={1.2}
      src={src.hover.filename}
      style={{
        width: processing === 'storyblok' ? '' : width,
        height: processing === 'storyblok' ? '' : height,
      }}
    />
  );
};

export default SbAnimatedImageComponent;
