import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { TextItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

const SbTextItemComponent: ReactDynamicComponent<TextItemStoryblok> = ({
  blok,
}) => {
  const { text } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <>{text}</>
    </div>
  );
};

export default SbTextItemComponent;
