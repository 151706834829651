import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { PortfoliosWithStatsStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

import './portfoliosWithStats.component.scss';

const SbPortfoliosWithStatsComponent: ReactDynamicComponent<PortfoliosWithStatsStoryblok> =
  ({ blok }) => (
    <div {...storyblokEditable(blok)}>
      <div className="portfolio-with-stats grid gap-2 my-2 md:grid-cols-2">
        <div className="w-full">
          {blok.portfolios && dynamicComponentsBlock(blok.portfolios[0])}
          <div className="stats-wrapper flex items-center item-no-image stats-bg text-white md:text-blue rounded-lg p-4 md:p-8">
            {dynamicComponentsBlock(blok.statistics)}
          </div>
        </div>
        <div className="flex w-full">
          {blok.portfolios &&
            blok.portfolios?.length > 1 &&
            dynamicComponentsBlock(blok.portfolios[1])}
        </div>
      </div>
    </div>
  );

export default SbPortfoliosWithStatsComponent;
