import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import Link from '../../../../components/common/link/link.component';
import LinkedIn from '../../../../assets/images/linkedin.svg';

import { TeamItemStoryblok } from '../../../../types';
import { ReactDynamicComponent } from '../../../common';

import './teamItem.component.scss';
import AdaptiveImage from '../../../../components/image/adaptiveImage.component';

const SbTeamItemComponent: ReactDynamicComponent<TeamItemStoryblok> = ({
  blok,
}) => {
  const { image, label, name, link } = blok;

  return (
    <div
      key={blok._uid}
      className="flex flex-col text-center team-item text-blue relative"
      {...storyblokEditable(blok)}
    >
      <div className="image-wrapper">
        <Link link={link} className="team-item__link">
          <LinkedIn
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
            }}
          />
        </Link>
        {image && (
          <Link link={link} className="team-item__img-link">
            <AdaptiveImage
              src={image[0].image?.filename || ''}
              alt={image[0].image?.alt || ''}
              processing={image[0].processing}
            />
          </Link>
        )}
      </div>
      <div className="Poppins-14-20-Bold lg:Poppins-16-24-Bold mt-5">
        {name}
      </div>
      <div className="Poppins-12-16-Regular lg:Poppins-14-20-Regular">
        {label}
      </div>
    </div>
  );
};

export default SbTeamItemComponent;
