import React, { useState, useEffect } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { Link } from 'gatsby';
import Cookies from 'js-cookie';
import { CookiesConsentStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

import './cookiesConsent.component.scss';

const SbCookiesConsentComponent: ReactDynamicComponent<CookiesConsentStoryblok> =
  ({ blok }) => {
    const [visible, setVisible] = useState(false);

    const { cookieLifetime, button } = blok;

    useEffect(() => {
      if (Cookies.get('consent') === undefined) {
        setVisible(true);
      }
    }, []);

    const accept = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      Cookies.set('consent', 'true', {
        sameSite: 'strict',
        expires: Number(cookieLifetime) * 30,
      });
      setVisible(false);
    };

    return (
      <div {...storyblokEditable(blok)}>
        <div className={`cookies-consent-popup ${visible ? 'show' : 'hidden'}`}>
          <div className="cookies-consent-popup__text-wrapper lg:Poppins-14-20-Medium Poppins-12-20-Medium">
            {blok.text.content[0].content[0].text}
            <Link
              className="cookies-consent-popup__text-link"
              to={`/${blok.link?.cached_url}/`}
            >
              {blok.text.content[0].content[1].text}
            </Link>
          </div>

          <div className="cookies-consent-popup__button-wrapper">
            <div
              className="cookies-consent-popup__button Poppins-16-24-SemiBold"
              onClick={(e) => accept(e)}
            >
              {dynamicComponentsBlock(button)}
            </div>
          </div>
        </div>
      </div>
    );
  };

export default SbCookiesConsentComponent;
