import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { PortfolioWithTestimonialStoryblok } from '../../types';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import { ReactDynamicComponent } from '../common';

import './portfolioWithTestimonial.component.scss';

const SbPortfolioWithTestimonialComponent: ReactDynamicComponent<PortfolioWithTestimonialStoryblok> =
  ({ blok }) => (
    <div {...storyblokEditable(blok)}>
      <div className="portfolio-with-testimonial mt-2 flex flex-col md:flex-row text-blue">
        <div className="md:w-6/12">
          {dynamicComponentsBlock(blok.portfolio)}
        </div>
        <div className="md:w-6/12 p-8 flex items-center portfolio-with-testimonial__description">
          {dynamicComponentsBlock(blok.testimonial)}
        </div>
      </div>
    </div>
  );

export default SbPortfolioWithTestimonialComponent;
