import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { ImageGalleryItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './imageGalleryItem.component.scss';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbImageGalleryItemComponent: ReactDynamicComponent<ImageGalleryItemStoryblok> =
  ({ blok }) => (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className="m-1">
        {blok.image && (
          <>
            <AdaptiveImage
              src={blok.image[0].image?.filename || ''}
              alt={blok.image[0].image?.alt || ''}
              className="rounded-xl md:rounded-2xl w-full"
              width="2000"
              processing={blok?.image[0].processing}
            />
            <div className="Poppins-12-16-Regular mx-auto text-center text-grey-2 w-2/3 mt-2">
              {blok.description}
            </div>
          </>
        )}
      </div>
    </div>
  );

export default SbImageGalleryItemComponent;
