import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer-ts';
import { CommonTextStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './common-text.component.scss';

const SbCommonText: ReactDynamicComponent<CommonTextStoryblok> = ({ blok }) => {
  const { content } = blok;

  return (
    <div
      {...storyblokEditable(blok)}
      className="common-text-component"
      id={blok.id}
    >
      {render(content)}
    </div>
  );
};

export default SbCommonText;
