import React, { FC } from 'react';
import './placeholder.component.scss';

interface Props {
  componentName: string;
}

const PlaceholderComponent: FC<Props> = ({ componentName }) => (
  <div className="placeholder-component">
    <p>
      The component <strong>{componentName}</strong> has not been created yet.
    </p>
  </div>
);

export default PlaceholderComponent;
