import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { BenefitsStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './benefits.component.scss';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbBenefitsComponent: ReactDynamicComponent<BenefitsStoryblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)} key={blok._uid}>
    <div className="benefits">
      <h2 className="Poppins-24-36-Bold md:Poppins-32-44-Bold lg:Poppins-42-56-Bold text-center mt-24 md:mt-32 mb-14">
        {blok.title}
      </h2>
      <div className="benefits__container grid lg:grid-cols-2">
        {blok.items?.map((item, index) => (
          <div
            key={item._uid}
            className={`benefits__element flex flex-col md:flex-row lg:flex-col items-center lg:max-w-md ${
              index % 2 === 1 ? '' : 'md:direction-rtl lg:direction-ltr'
            }`}
          >
            <div className="row-span-1 flex justify-center mx-16 lg:mx-0 lg:self-start">
              {item.image && (
                <AdaptiveImage
                  src={item.image[0].image?.filename || ''}
                  alt={item.image[0].image?.alt || ''}
                  className="rounded mb-1 w-20 h-20 md:w-96 md:h-24 lg:w-24"
                  processing={item.image[0]?.processing}
                />
              )}
            </div>
            <div className="row-span-2">
              <h3 className="Poppins-18-28-Bold lg:Poppins-28-36-Bold mt-2 text-center md:text-left">
                {item.title}
              </h3>
              <div className="Lora-17-26-Regular md:Lora-18-30-Regular lg:Lora-21-36-Regular text-left lg:mt-4">
                {item.label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default SbBenefitsComponent;
