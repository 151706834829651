import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import SbAnimatedImageComponent from '../../components/image/animatedImage.component';
import { BlockWithTilesStoryblok, BenefitsItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './blockWithTiles.component.scss';

const blockWithTilesComponent: ReactDynamicComponent<BlockWithTilesStoryblok> =
  ({ blok }) => {
    const [isSelectedBenefit, setIsSelectedBenefit] = useState({
      uid: '',
      value: false,
    });
    const menuItem = (item: BenefitsItemStoryblok, index: number) => (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <a
        key={index}
        className={
          index === 1 ? 'blockitem m-1 p-4 launch' : 'blockitem m-1 p-4'
        }
        onMouseOver={() =>
          setIsSelectedBenefit({ uid: item._uid, value: true })
        }
        onTouchStart={() =>
          setIsSelectedBenefit({ uid: item._uid, value: true })
        }
        onMouseLeave={() =>
          setIsSelectedBenefit({ uid: item._uid, value: false })
        }
        onTouchEnd={() =>
          setIsSelectedBenefit({ uid: item._uid, value: false })
        }
        href={item.link?.cached_url}
      >
        {item.image && (
          <div className="flex items-center">
            <SbAnimatedImageComponent
              isSelectedBenefit={isSelectedBenefit}
              _uid={item._uid}
              src={item.image[0]}
              component="animatedImage"
            />
            <div className="title">{item.title}</div>
          </div>
        )}
        <p className="info mt-4">{item.label}</p>
      </a>
    );

    return (
      <div {...storyblokEditable(blok)} key={blok._uid}>
        <div className="wrapper">
          {blok.items &&
            blok.items.map((item: BenefitsItemStoryblok, index: number) =>
              menuItem(item, index)
            )}
        </div>
      </div>
    );
  };

export default blockWithTilesComponent;
