import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { TestimonialStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import Quotes from '../../assets/images/Quotes.svg';

import './testimonial.component.scss';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbTestimonialComponent: ReactDynamicComponent<TestimonialStoryblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)}>
    <div className="testimonial-item text-blue">
      <Quotes className="quotes-testimonial mb-4" />
      <div className="testimonial-quote Lora-17-24-Italic">{blok.quote}</div>
      <div className="flex items-center mt-10">
        {blok.showImage ? (
          <div className="w-12 h-12 mr-4">
            <AdaptiveImage
              src={blok.image?.filename || ''}
              alt={blok.image?.alt || ''}
              processing=""
            />
          </div>
        ) : null}
        <div>
          <div className="Poppins-14-20-Bold lg:Poppins-18-28-Bold">
            {blok.author}
          </div>
          <div className="Poppins-14-20-Regular lg:Poppins-18-28-Regular">
            {blok.position}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SbTestimonialComponent;
