import React from 'react';
import { SbEditableContent } from 'storyblok-react';
import { Components, ReactDynamicComponent } from './common';
import PlaceholderComponent from './placeholder/placeholder.component';

const DynamicComponent: ReactDynamicComponent<SbEditableContent> = ({
  blok,
  index,
}) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component];
    return <Component blok={blok} key={blok._uid} index={index} />;
  }
  return blok.component ? (
    <PlaceholderComponent componentName={blok.component} key={blok._uid} />
  ) : null;
};

export default DynamicComponent;
