import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { storyblokEditable } from '@storyblok/react';
import { VacanciesStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import MapIcon from '../../assets/images/mapIcon-grey.svg';
import TabIcon from '../../assets/images/newTabIcon.svg';
import { API_KEY } from '../../utils/constants';
import Link from '../../components/common/link/link.component';
import './vacancies.component.scss';

type Vacancy = {
  department: {
    name: string;
  };
  location: {
    name: string;
  };
  title: string;
  id: number;
  state: string;
  post_on_careers: boolean;
};

const SbVacanciesBlock: ReactDynamicComponent<VacanciesStoryblok> = ({
  blok,
}) => {
  const { id, button, label, label2, title, title2, sourceId } = blok;
  const [actualVacancies, setActualVacancies] = useState<Vacancy[]>([]);
  const [actualCategories, setActualCategories] = useState<any>([]);

  const fetchVacancies = () => {
    const options: AxiosRequestConfig = {
      method: 'GET',
      url: 'https://app.peopleforce.io/api/public/v1/recruitment/vacancies',
      params: { 'status[]': 'opened' },
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': API_KEY,
      },
    };

    setActualVacancies([]);
    setActualCategories([]);

    return axios
      .request(options)
      .then((response) => {
        let vacanciesList = response.data.data;
        const categoriesList: string[] = [];

        vacanciesList = vacanciesList.filter(
          (item: Vacancy) => item.id !== 20073 && item.post_on_careers
        );

        setActualVacancies(vacanciesList);

        vacanciesList.map((currentVacancy: Vacancy) => {
          const categoryName = currentVacancy.department.name;

          if (categoriesList.includes(categoryName)) {
            return;
          }

          return categoriesList.push(categoryName);
        });

        setActualCategories(categoriesList);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const renderVacancy = (category: string) =>
    actualVacancies?.map((vacancy: Vacancy) => {
      if (vacancy.department.name === category && vacancy.id) {
        return (
          <div className="vacancies__category-vacancy" key={vacancy.id}>
            <div className="vacancies__vacancy-description">
              <div className="vacancies__vacancy-spec Poppins-18-28-Bold">
                {vacancy.title}
              </div>
              <div className="vacancies__vacancy-location Poppins-12-20-Medium">
                <MapIcon />
                {vacancy.location.name}
              </div>
            </div>
            <div className="vacancies__vacancy-link Poppins-16-24-Bold">
              <Link
                link={{
                  cached_url: `https://tulaco.peopleforce.io/careers/${vacancy.id}?source_id=${sourceId}`,
                }}
                className="flex flex-row items-center"
              >
                Apply now
                <TabIcon className="ml-1.5 mb-1.5" />
              </Link>
            </div>
          </div>
        );
      }

      return null;
    });

  const renderCategories = () =>
    actualCategories?.map((category: string) => {
      let isCategoryExists = false;

      actualVacancies?.map((vacancy: Vacancy) => {
        if (vacancy.department.name === category && vacancy.id) {
          isCategoryExists = true;
        }

        return isCategoryExists;
      });

      return isCategoryExists ? (
        <div className="vacancies__category" key={category}>
          <div className="vacancies__category-title Poppins-12-20-Medium">
            {category}
          </div>
          <div className="vacancies__category-vacancies-list">
            {renderVacancy(category)}
          </div>
        </div>
      ) : null;
    });

  useEffect(() => {
    fetchVacancies();
  }, []);

  return (
    <div {...storyblokEditable(blok)}>
      <div className="vacancies container" id={id}>
        <div className="vacancies__body">
          <div className="vacancies__body-title Poppins-28-36-Bold md:Poppins-42-56-Bold ">
            {title}
          </div>
          <div className="vacancies__body-description Poppins-18-28-Regular">
            {label}
          </div>

          <div className="vacancies__categories-list">{renderCategories()}</div>
        </div>
        <div className="vacancies__footer">
          <div className="vacancies__footer-title Poppins-28-36-Bold md:Poppins-32-44-Bold">
            {title2}
          </div>
          <div className="vacancies__footer-description-wrapper">
            <div className="vacancies__footer-description Lora-17-24-Italic md:Lora-24-32-Italic">
              {label2}
            </div>
            <Link
              link={button && button[0].link}
              className="flex flex-row items-center"
            >
              <div className="vacancies__footer-button Poppins-14-20-Bold">
                Get in touch
                <TabIcon className="ml-1.5" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbVacanciesBlock;
