import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ImageWithLinkStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import LinkComponent from '../../components/common/link/link.component';
import Image2Wrapper from '../../components/image/Image2Wrapper';

const SbImageWithLink: ReactDynamicComponent<ImageWithLinkStoryblok> = ({
  blok,
}) => {
  const { link, image2 } = blok;

  return (
    <LinkComponent link={link} {...storyblokEditable(blok)}>
      <Image2Wrapper image2={image2} />
    </LinkComponent>
  );
};

export default SbImageWithLink;
