import React from 'react';
import DynamicComponent from './dynamicComponent';

const dynamicComponentsBlock = (components: any) =>
  Array.isArray(components) ? (
    components.map((component, index) => (
      <DynamicComponent blok={component} key={component._uid} index={index} />
    ))
  ) : (
    <DynamicComponent blok={components} key={components._uid} />
  );

export default dynamicComponentsBlock;
