import React, { useContext, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';

import Slider, { SwipeDirection } from 'react-slick';
import Left from '../../assets/images/Left.svg';
import Right from '../../assets/images/Right.svg';

import './locations.component.scss';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import PageContext from '../pageContext';

import { ReactDynamicComponent } from '../common';
import { LocationsStoryblok } from '../../types';

const SbLocationsComponent: ReactDynamicComponent<LocationsStoryblok> = ({
  blok,
}) => {
  const { title, items, image } = blok;

  const { screen } = useContext(PageContext);
  const slideWidth = screen?.isMobile ? 452 : 464;
  const [slide, setSlide] = useState(0);
  const [sliderOffset, setSliderOffset] = useState(0);
  const [isAnimate, setIsAnimate] = useState(false);

  const sliderButtonsHandler = (direction: string) => {
    const slidersCount = items?.length || 0;
    let currentSlide = slide;

    setIsAnimate(true);

    const rightDirectionLimit = screen?.isMobile
      ? slidersCount - 1
      : slidersCount - 2;

    if (direction === 'right' && currentSlide + 1 <= rightDirectionLimit) {
      currentSlide += 1;
    }

    if (direction === 'left' && currentSlide - 1 >= 0) {
      currentSlide -= 1;
    }

    setSlide(currentSlide);
    return setSliderOffset(-currentSlide * slideWidth);
  };

  const renderSlider = () => {
    const settings = {
      dots: false,
      touchThreshold: 100,
      draggable: false,
      infinite: false,
      vertical: false,
      swipeEvent: (swipeDirection: SwipeDirection) => {
        setIsAnimate(false);
        if (swipeDirection === 'left') {
          sliderButtonsHandler('right');
        } else if (swipeDirection === 'right') {
          sliderButtonsHandler('left');
        }
      },
    };

    return (
      <div className="locations__slider">
        <div className="locations__slider-buttons">
          <div
            className="locations__slider-button"
            onClick={() => sliderButtonsHandler('left')}
          >
            <Left />
          </div>
          <div
            className="locations__slider-button"
            onClick={() => sliderButtonsHandler('right')}
          >
            <Right />
          </div>
        </div>
        <div className="locations__items-wrapper">
          <div className="locations__slider-left-side" />
          <div
            className="locations__items-holder"
            style={{
              marginLeft: `${sliderOffset}px`,
              transition: `${isAnimate ? 'margin-left 1.5s' : ''}`,
            }}
          >
            <Slider {...settings}>
              {items?.map((item) => (
                <div
                  className="locations__item-wrapper"
                  key={item._uid}
                  data-key={item._uid}
                >
                  {item.image && (
                    <AdaptiveImage
                      src={item.image[0].image?.filename || ''}
                      mobilesrc={item.image[0].mobileImage?.filename || ''}
                      alt={item.image[0]?.image?.alt || ''}
                      processing={item.image[0].processing}
                    />
                  )}
                  <div className="locations__item-info">
                    <div className="Poppins-14-20-Regular">{item.label}</div>
                    <div className="Poppins-18-28-Bold">{item.title}</div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div {...storyblokEditable(blok)}>
      <div className="locations">
        <div className="locations__header">
          <div className="locations__header-title Poppins-28-36-Bold lg:Poppins-42-56-Bold">
            {title}
          </div>
          <div className="locations__header-map">
            <AdaptiveImage
              src={(image && image[0].image?.filename) || ''}
              mobilesrc={(image && image[0].mobileImage?.filename) || ''}
              alt={(image && image[0]?.image?.alt) || ''}
              processing={image && image[0].processing}
            />
          </div>
        </div>
        {renderSlider()}
      </div>
    </div>
  );
};

export default SbLocationsComponent;
