import React, { useState, useEffect, useRef } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { TextBlockStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './textBlock.component.scss';

const SbTextBlock: ReactDynamicComponent<TextBlockStoryblok> = ({ blok }) => {
  const {
    animation,
    header,
    text,
    alignment,
    blockAlignment,
    style,
    headingStyle,
  } = blok;
  const [isRender, setIsRender] = useState(false);

  const target = useRef() as React.RefObject<HTMLDivElement>;
  const intersectionObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  const intersectionObserverCallback = (
    entries: { isIntersecting: boolean }[]
  ) => {
    if (entries[0].isIntersecting) {
      setIsRender(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      intersectionObserverCallback,
      intersectionObserverOptions
    );

    if (target.current) {
      observer.observe(target.current);
    }
  }, []);

  const renderAnimatedText = (animatedText?: any, animationType?: string) => {
    const animTextToChars = animatedText.split('');

    return animTextToChars.map((char: string, index: number) => {
      const fadeInSymbolDelay = 0.5 + index / 50;
      const animationEffect = {
        animation:
          animationType === 'wave'
            ? `fade-in 0.5s ${fadeInSymbolDelay}s forwards`
            : '',
      };

      return (
        <span
          aria-hidden="true"
          style={animationEffect}
          className="animated-text-character"
          key={String(index)}
        >
          {char}
        </span>
      );
    });
  };

  const renderSimpleText = () =>
    blockAlignment === 'columns2WithTitle' ? (
      <div className={`text-${alignment || 'left'} text-columns-wrapper gap-7`}>
        <div className="text-columns-block">
          <div
            className={`text-columns-block-component__text-styles_${headingStyle} Poppins-28-36-Bold lg:Poppins-28-36-Bold`}
          >
            {header}
          </div>
          <div
            className={`text-block-subtitle
          ${
            style === 'poppins-shrink'
              ? 'Poppins-20-30-Regular lg:Poppins-20-30-Regular'
              : 'Lora-18-34-Regular lg:Lora-21-36-Regular'
          }`}
          >
            {text?.split(/\r?\n/)[0]}
          </div>
        </div>
        <div
          className={`text-block-subtitle
        ${
          style === 'poppins-shrink'
            ? 'Poppins-20-30-Regular lg:Poppins-20-30-Regular'
            : 'Lora-18-34-Regular lg:Lora-21-36-Regular'
        }
        text-columns-block`}
        >
          {text?.split(/\r?\n/)[2]}
        </div>
      </div>
    ) : (
      <div className={`text-${alignment || 'left'} `}>
        <div
          className={`text-block-component__text-styles_${headingStyle} Poppins-24-36-Bold lg:Poppins-28-36-Bold`}
        >
          {header}
        </div>
        <div className="text-block-subtitle Lora-18-34-Regular lg:Lora-21-36-Regular">
          {text}
        </div>
      </div>
    );

  const renderTextBlock = () =>
    animation === 'none' || !animation ? (
      <div
        className={`text-block-wrapper ${
          style === 'italic'
            ? 'Lora-24-40-Italic lg:Lora-34-56-Italic'
            : 'Lora-18-34-Regular'
        } ${
          blockAlignment === 'columns2WithTitle' && style === 'poppins-shrink'
            ? ''
            : 'w-11/12 lg:w-8/12 pl-6'
        } text-center`}
      >
        {renderSimpleText()}
      </div>
    ) : (
      isRender && (
        <div
          className={`animated-text-wrapper ${
            style === 'italic'
              ? 'Lora-24-40-Italic lg:Lora-34-56-Italic'
              : 'Lora-18-34-Regular'
          } text-center`}
        >
          {renderAnimatedText(text, animation)}
        </div>
      )
    );

  return (
    <div {...storyblokEditable(blok)} className="text-block-component">
      <div className="wide">
        <div className="container">
          <div
            className={`text-block text-blue md:flex justify-${
              animation === 'none' && blockAlignment ? blockAlignment : 'center'
            }`}
            ref={target}
          >
            {renderTextBlock()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbTextBlock;
