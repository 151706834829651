interface ImageUrlBuilder {
  src: string;
  width: string;
}

interface ImageMobileSrcBuilder {
  src: string;
  mobilesrc?: string;
  width: string;
}
interface ImageTabletSrcBuilder {
  src: string;
  tabletsrc?: string;
  width: string;
}

export function imageUrlBuilder(data: ImageUrlBuilder): string {
  const img2: string = '//img2.storyblok.com';
  const srcm = data.src;
  const result = data.src?.match(/^(.*?)\/\/a.storyblok.com(.*?)$/);
  const prefix = result?.[1];
  const postfix = result?.[2];
  return srcm.includes('.svg')
    ? srcm
    : `${prefix}${img2}/${data.width}x0${postfix}`;
}

export function imageMobileSrcBuilder(data: ImageMobileSrcBuilder): string {
  const img2: string = '//img2.storyblok.com';
  const srcm = data.mobilesrc || data.src;

  const result = srcm?.match(/^(.*?)\/\/a.storyblok.com(.*?)$/);
  const prefix = result?.[1];
  const postfix = result?.[2];
  return srcm.includes('.svg')
    ? srcm
    : `${prefix}${img2}/${parseInt(data.width, 10) * 2}x0${postfix} 2x`;
}
export function imageTabletSrcBuilder(data: ImageTabletSrcBuilder): string {
  const img2: string = '//img2.storyblok.com';
  const srcm = data.tabletsrc || data.src;

  const result = srcm?.match(/^(.*?)\/\/a.storyblok.com(.*?)$/);
  const prefix = result?.[1];
  const postfix = result?.[2];

  return srcm.includes('.svg')
    ? srcm
    : `${prefix}${img2}/${parseInt(data.width, 10) * 2}x0${postfix} 2x`;
}
