import React, { useState, useEffect, useContext } from 'react';
import ReactTypingEffect from 'react-typing-effect';
import { storyblokEditable } from '@storyblok/react';

import { MainBannerStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './mainBanner.component.scss';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import PageContext from '../pageContext';

const SbMainBanner: ReactDynamicComponent<MainBannerStoryblok> = ({ blok }) => {
  const [textForAnimate, setTextForAnimate] = useState([] as string[]);
  const { pageOffset = 0 } = useContext(PageContext);

  useEffect(() => {
    blok.animatedText?.map((animatedTextItem) =>
      // @ts-ignore
      setTextForAnimate((prevTexts) => [...prevTexts, animatedTextItem.text])
    );
  }, []);

  return (
    <div {...storyblokEditable(blok)}>
      <div className="main-banner h-screen-4/5 flex content-center md:pl-23 md:pr-23">
        <div className="flex items-center lg:space-x-20">
          <div className="main-banner__content flex flex-col justify-between items-center lg:items-start lg:flex-row h-fit">
            <div className="w-12/12 lg:w-7/12 pb-1.5">
              <h1 className="main-banner__header Poppins-50-64-Bold">
                {blok.header}
              </h1>
              <div className="main-banner__text-animate Poppins-50-64-Bold text-red">
                <ReactTypingEffect
                  text={textForAnimate}
                  speed={100}
                  eraseSpeed={100}
                  eraseDelay={1000}
                  cursorClassName="font-thin"
                />
              </div>
            </div>
            <div className="main-banner__right-column flex flex-col w-12/12">
              <div className="main-banner__description Lora-17-24-Italic lg:Lora-24-32-Italic mb-11">
                {blok.description}
              </div>
              <div className="mb-5">
                {pageOffset <= 450 && dynamicComponentsBlock(blok.button)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbMainBanner;
