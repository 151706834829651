import React from 'react';
import { Helmet } from 'react-helmet';
import { IGeneric } from '../../common-types';
import { PageStoryblok } from '../../types';

const renderMetaTag = (
  name: string,
  property: string,
  content: string | undefined
) =>
  content ? <meta name={name} property={property} content={content} /> : null;

type SeoMeta = PageStoryblok['seoMeta'];

// twitter card: summary, summary_large_image
const MetaTags = ({ data }: IGeneric<SeoMeta>) => (
  <Helmet>
    {data?.title ? <title>{data.title}</title> : null}
    {renderMetaTag('description', 'description', data?.description)}
    {renderMetaTag('og:description', 'og:description', data?.og_description)}
    {renderMetaTag('image', 'og:image', data?.og_image)}
    {renderMetaTag('title', 'og:title', data?.og_title)}
    {renderMetaTag('twitter:card', 'twitter:card', 'summary')}
    {renderMetaTag(
      'twitter:description',
      'twitter:description',
      data?.twitter_description
    )}
    {renderMetaTag('twitter:image', 'twitter:image', data?.twitter_image)}
    {renderMetaTag('twitter:title', 'twitter:title', data?.twitter_title)}
  </Helmet>
);

export default MetaTags;
