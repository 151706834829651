import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { CultureStoryblok } from '../../types';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import './culture.component.scss';

const SbCulture: ReactDynamicComponent<CultureStoryblok> = ({ blok }) => {
  const { items } = blok;

  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const ItemTitle = (title: string | undefined, index: number) => (
    <button
      type="button"
      key={index}
      className={activeItemIndex === index ? 'active' : ''}
      onClick={() => {
        setActiveItemIndex(index);
      }}
    >
      {title}
    </button>
  );

  return (
    <div {...storyblokEditable(blok)} className="culture-component wide">
      <div className="culture-component-content container">
        <div className="culture-component__titles">
          {items?.map((item, index) => ItemTitle(item.title, index))}
        </div>
        <div className="culture-component__item-wrapper">
          {items?.length
            ? dynamicComponentsBlock(items[activeItemIndex])
            : null}
        </div>
      </div>
    </div>
  );
};

export default SbCulture;
