import { Link } from 'gatsby';
import React, { FC, ReactNode } from 'react';
import { LinkItemStoryblok } from '../../../types';

import { addTrailingSlashes } from '../../../utils/urlRefactor';

interface Props {
  link: LinkItemStoryblok | any;
  className?: string;
  children?: ReactNode;
  solutionsMenuOpen?: Function | any;
  solutionsMenuClose?: Function | any;
}

const LinkComponent: FC<Props> = (props) => {
  const { children, link, className } = props;

  const linkUrl = link?.anchor
    ? `/${link?.cached_url}#${link.anchor}`
    : addTrailingSlashes(`/${link?.cached_url}`);

  return (
    <>
      {link?.linktype === 'story' ? (
        <Link
          className={className}
          to={linkUrl}
          onMouseEnter={() =>
            link?.cached_url === 'solutions' ? props.solutionsMenuOpen() : ''
          }
          onMouseLeave={() =>
            link?.cached_url === 'solutions' ? props.solutionsMenuClose() : ''
          }
        >
          {children}
        </Link>
      ) : (
        <a
          href={link?.cached_url}
          className={className}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      )}
    </>
  );
};

export default LinkComponent;
