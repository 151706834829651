import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer-ts';
import { TextBlockStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './textBlock2.component.scss';

const SbTextBlock2: ReactDynamicComponent<TextBlockStoryblok> = ({ blok }) => {
  const { animation, header, text, alignment, blockAlignment, style } = blok;

  return (
    <div {...storyblokEditable(blok)}>
      <div
        className={`text-block-2 text-blue flex justify-${
          animation === 'none' && blockAlignment ? blockAlignment : 'center'
        } mb-6`}
      >
        <div
          className={`text-block-wrapper ${
            style === 'italic'
              ? 'Lora-24-40-Italic lg:Lora-34-56-Italic'
              : 'Lora-18-34-Regular'
          } w-11/12 lg:w-8/12 pl-6 text-center`}
        >
          <div className={`text-${alignment || 'left'} `}>
            <h2 className="Poppins-24-36-Bold lg:Poppins-28-36-Bold">
              {header}
            </h2>
            <div className="Lora-18-34-Regular lg:Lora-21-36-Regular text-content">
              {render(text)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbTextBlock2;
