import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { AdvantagesItemStoryblok, AdvantagesStoryblok } from '../../types';

import './advantages.component.scss';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
// eslint-disable-next-line import/order
import { render } from 'storyblok-rich-text-react-renderer-ts';

const SbAdvantages: ReactDynamicComponent<AdvantagesStoryblok> = ({ blok }) => {
  const advantageItem = (item: AdvantagesItemStoryblok, index: number) => (
    <div key={index} className="items">
      {item.icon && (
        <div className="flex items-center">
          <AdaptiveImage
            src={item.icon[0].image?.filename || ''}
            mobilesrc={item.icon[0].mobileImage?.filename}
            alt={item.icon[0].image?.alt || ''}
            processing={item?.icon[0].processing}
            className="mr-5"
          />
          {item.text && (
            <div className="advantages-text">{render(item.text[0].text)}</div>
          )}
        </div>
      )}
    </div>
  );
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className="advantages">
        {blok.background && blok.background[0] && (
          <AdaptiveImage
            src={blok.background[0].image?.filename || ''}
            mobilesrc={blok.background[0].mobileImage?.filename || ''}
            alt={blok.background[0].image?.alt || ''}
            processing={blok.background[0].processing}
          />
        )}
        <div className="advantages-item">
          {blok.items &&
            blok.items.map((item: AdvantagesItemStoryblok, index: number) =>
              advantageItem(item, index)
            )}
        </div>
      </div>
    </div>
  );
};
export default SbAdvantages;
