import React from 'react';
import {
  CodeBlock,
  dracula,
  far,
  googlecode,
  monoBlue,
  atomOneDark,
  atomOneLight,
} from 'react-code-blocks';
import { ReactDynamicComponent } from '../common';
import { CodeSnippetStoryblok } from '../../types';

import './codeSnippet.component.scss';

const themeList: any = {
  dracula,
  far,
  googlecode,
  monoBlue,
  atomOneDark,
  atomOneLight,
};

const SbCodeSnippet: ReactDynamicComponent<CodeSnippetStoryblok> = ({
  blok,
}) => {
  const { code, isShowLineNumbers, language, theme } = blok;

  return (
    <div className="code-block">
      <CodeBlock
        text={code}
        language={language}
        showLineNumbers={isShowLineNumbers}
        theme={themeList[theme]}
      />
    </div>
  );
};

export default SbCodeSnippet;
