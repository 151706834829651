import React, { FC } from 'react';
import AdaptiveImage from './adaptiveImage.component';
import { ImageStoryblok } from '../../types';

const Image2Wrapper: FC<any> = (props) => {
  let output;

  if (Array.isArray(props.image2)) {
    output = props.image2.map((image: ImageStoryblok, index: number) => (
      <AdaptiveImage
        src={image.image?.filename || ''}
        mobilesrc={image.mobileImage?.filename || image.image?.filename || ''}
        alt={image.image?.alt || ''}
        processing={image.processing}
        width={image.width}
        key={String(index)}
      />
    ));
  } else {
    output = (
      <AdaptiveImage
        src={props.image2.image?.filename || ''}
        mobilesrc={
          props.image2.mobileImage?.filename ||
          props.image2.image?.filename ||
          ''
        }
        alt={props.image2.image.alt}
        processing={props.image2.processing}
        width={props.image2.width}
      />
    );
  }

  return output;
};

export default Image2Wrapper;
