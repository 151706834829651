import React, { FC, useMemo } from 'react';
import {
  imageMobileSrcBuilder,
  imageTabletSrcBuilder,
  imageUrlBuilder,
} from './helpers';

interface Props {
  src: string;
  mobilesrc?: string;
  tabletsrc?: string;
  alt?: string;
  title?: string;
  width?: string;
  className?: string;
  processing: string | undefined;
}

const AdaptiveImage: FC<Props> = ({
  src,
  mobilesrc,
  tabletsrc,
  alt,
  title = '',
  width = '600',
  className = '',
  processing,
}) => {
  const mobileUrl = useMemo(
    () =>
      imageMobileSrcBuilder({
        src,
        width,
        mobilesrc,
      }),
    [mobilesrc, width]
  );
  const tabletUrl = useMemo(
    () =>
      imageTabletSrcBuilder({
        src,
        width,
        tabletsrc,
      }),
    [tabletsrc, width]
  );
  const newUrl = useMemo(
    () =>
      imageUrlBuilder({
        src,
        width,
      }),
    [src, width]
  );

  return (
    <picture>
      <source
        media="(max-width: 768px)"
        srcSet={mobilesrc === '' ? newUrl : mobileUrl}
      />
      <source
        media="(max-width: 1100px)"
        srcSet={tabletsrc === '' ? newUrl : tabletUrl}
      />
      <img
        className={className}
        src={processing === 'storyblok' ? newUrl : src}
        alt={alt}
        title={title || ''}
        width={processing === 'storyblok' ? '' : width}
      />
    </picture>
  );
};

export default AdaptiveImage;
