/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { FC, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/layout.scss';
import {
  FooterStoryblok,
  HeaderStoryblok,
  CookiesConsentStoryblok,
} from '../types';
import StoryblokService from '../utils/storyblok-service';
import PageContext, { IContext, ScrollDirection } from './pageContext';
import DynamicComponent from './dynamicComponent';
import BannerBg from '../components/banner-bg/banner-bg.component';
import { breakPoints } from '../utils/constants';
import { addTrailingSlashes } from '../utils/urlRefactor';

interface Props {
  className: string;
  header: HeaderStoryblok[];
  footer: FooterStoryblok[];
  cookiesConsent: CookiesConsentStoryblok[];
  full_slug: string;
  path?: string;
  children: any;
  noindex?: boolean | undefined;
  showBlock?: boolean | undefined;
}

const Layout: FC<Props> = ({
  children,
  className,
  header,
  footer,
  cookiesConsent,
  full_slug,
  path,
  noindex,
  showBlock,
}) => {
  const [globalData, setGlobalData] = useState<{
    cookiesConsent: CookiesConsentStoryblok[];
    header: HeaderStoryblok[];
    footer: FooterStoryblok[];
  }>({ cookiesConsent, header, footer });
  const [pageOffset, setStatePageOffset] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
    ScrollDirection.up
  );
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [screenHeight, setScreenHeight] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);

  const pageOffsetRef = useRef(pageOffset);
  const setPageOffset = (x: number) => {
    pageOffsetRef.current = x;
    setStatePageOffset(x);
  };

  const handleScroll = () => {
    const offset = window.pageYOffset;
    setScrollDirection(
      offset - pageOffsetRef.current > 0
        ? ScrollDirection.down
        : ScrollDirection.up
    );
    setPageOffset(offset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await StoryblokService.get(`cdn/stories/global`);
        setGlobalData(
          response.data && response.data.story && response.data.story.content
        );
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    };

    fetchData();
  }, []);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    updateScreenWidth();
    return () => window.removeEventListener('resize', updateScreenWidth);
  }, []);

  useEffect(() => {
    setIsMobile(screenWidth <= breakPoints.mobile);
    setIsTablet(
      screenWidth > breakPoints.mobile && screenWidth <= breakPoints.tablet
    );
  }, [screenWidth]);

  const Context: IContext = {
    fullSlug: full_slug,
    pageOffset,
    scrollDirection,
    screen: {
      width: screenWidth,
      height: screenHeight,
      isMobile,
      isTablet,
    },
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      />
      {noindex ? (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      ) : null}
      {/* @ts-ignore */}
      <Helmet>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="canonical"
          href={
            path
              ? `https://tula.co${addTrailingSlashes(path)}`
              : `https://tula.co/${addTrailingSlashes(full_slug)}`
          }
        />
      </Helmet>
      <Helmet
        script={[
          {
            src: 'https://app.storyblok.com/f/storyblok-v2-latest.js',
            type: 'text/javascript',
          },
          {
            innerHTML: `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
            type: 'text/javascript',
          },
          {
            innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MHW95ZG')`,
            type: 'text/javascript',
          },
          {
            // FB Pixel code
            innerHTML: `!function(e,t,n,o,p,i,a){e[o]||((p=e[o]=function(){p.process?p.process.apply(p,arguments):p.queue.push(arguments)}).queue=[],p.t=+new Date,(i=t.createElement(n)).async=1,i.src="https://pvdpix.com/pixel.js?t="+864e5*Math.ceil(new Date/864e5),(a=t.getElementsByTagName(n)[0]).parentNode.insertBefore(i,a))}(window,document,"script","pvd"),pvd("init","8eb1eb8c-8b7b"),pvd("event","pageload")`,
            type: 'text/javascript',
          },
        ]}
      />
      {/* @ts-ignore */}
      <Helmet>
        <body className={className} />
      </Helmet>
      <PageContext.Provider value={Context}>
        <div className="mx-auto page-wrapper">
          {showBlock &&
            globalData?.header?.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          {children?.props?.blok?.body?.[0]?.component === 'mainBanner' && (
            <div>
              <BannerBg />
            </div>
          )}
          <main
            className={
              showBlock
                ? `text-blue container max-w-screen-lg`
                : `text-blue container-contact`
            }
          >
            {children}
          </main>

          {showBlock &&
            globalData?.footer?.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          {globalData?.cookiesConsent?.map((blok) => (
            <DynamicComponent blok={blok} key={blok._uid} />
          ))}
        </div>
      </PageContext.Provider>
    </>
  );
};

export default Layout;
