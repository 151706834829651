import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { JobOpeningsWithTextStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

import './jobOpeningsWithText.component.scss';

const SbJobOpeningsWithText: ReactDynamicComponent<JobOpeningsWithTextStoryblok> =
  ({ blok }) => (
    <div className="job-openings-w-text" {...storyblokEditable(blok)}>
      <div className="job-openings-w-text__content">
        {dynamicComponentsBlock(blok.textItems)}
      </div>
      <div className="job-openings-w-text__openings">
        {dynamicComponentsBlock(blok.jobOpenings)}
      </div>
    </div>
  );

export default SbJobOpeningsWithText;
