import React, { useContext, useEffect, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';

import Slider, { CustomArrowProps } from 'react-slick';
import NavLeft from '../../assets/images/testimonials-nav-left.svg';
import NavRight from '../../assets/images/testimonials-nav-right.svg';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

import { TestimonialsWithTextStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import PageContext from '../pageContext';
import './testimonialsWithText.component.scss';

const SbTestimonialsWithText: ReactDynamicComponent<TestimonialsWithTextStoryblok> =
  ({ blok }) => {
    const { screen } = useContext(PageContext);
    const [centerPadding, setCenterPadding] = useState('0');
    const screenWidth = screen?.width || 1100;

    const calculateSlideWidth = () => {
      if (screen?.isMobile) {
        return 296;
      } else if (screen?.isTablet) {
        return 615;
      } else {
        return 900;
      }
    };
    const slideWidth = calculateSlideWidth();

    useEffect(() => {
      const newCenterPadding = `${(screenWidth - slideWidth) / 2}px`;
      setCenterPadding(newCenterPadding);
    }, [slideWidth, screenWidth]);

    const { testimonials, header } = blok;

    const PrevArrow: React.FC<CustomArrowProps> = ({
      currentSlide,
      slideCount,
      ...props
    }) => (
      <button {...props} type="button" className="custom-prev-arrow">
        <NavLeft />
      </button>
    );

    const NextArrow: React.FC<CustomArrowProps> = ({
      currentSlide,
      slideCount,
      ...props
    }) => (
      <button {...props} type="button" className="custom-next-arrow">
        <NavRight />
      </button>
    );

    const renderSlider = () => {
      const settings = {
        centerMode: true,
        centerPadding,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        swipeToSlide: true,
        speed: 500,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
      };

      return (
        <Slider {...settings}>
          {testimonials?.map((item) => (
            <div
              className="testimonials__item-wrapper "
              key={item._uid}
              data-key={item._uid}
            >
              {item.image && (
                <AdaptiveImage
                  src={item.image?.filename || ''}
                  mobilesrc={item.mobileImage?.filename || ''}
                  alt={item.image?.alt || ''}
                  processing="storyblok"
                />
              )}
              <div className="text-blue w-full" key={item._uid}>
                <div className="info">{item.quote}</div>
                <div className="flex items-center mt-4">
                  <div>
                    <div className="Poppins-14-20-Bold lg:Poppins-18-28-Bold">
                      {item.author}
                    </div>
                    <div className="Poppins-12-16-Regular lg:Poppins-18-28-Regular">
                      {item.position}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      );
    };

    return (
      <div className="testimonials_wraper">
        <div {...storyblokEditable(blok)}>
          <div className="testimonials">
            <div className="testimonials__header">
              <div className="testimonials__header-title Poppins-24-36-Bold md:Poppins-42-56-Bold">
                {header}
              </div>
            </div>
            {renderSlider()}
          </div>
        </div>
      </div>
    );
  };

export default SbTestimonialsWithText;
