import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import {
  PortfoliosWithHeaderStoryblok,
  PortfolioDefaultStoryblok,
} from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

import './portfoliosWithHeader.component.scss';

const SbPortfoliosWithHeaderComponent: ReactDynamicComponent<PortfoliosWithHeaderStoryblok> =
  ({ blok }) => {
    const { button, header, portfolios, text } = blok;

    const renderPortfolios = (
      portfoliosCollection: PortfolioDefaultStoryblok[] | undefined
    ) =>
      portfoliosCollection?.map((item) => (
        <div className="portfolio-part" key={item._uid}>
          {dynamicComponentsBlock(item)}
        </div>
      ));

    return (
      <div {...storyblokEditable(blok)}>
        <div className="portfolio-with-header text-blue mt-28 md:mt-32 lg:mt-32">
          <h2 className="Poppins-28-36-Bold lg:Poppins-42-56-Bold text-center mb-1">
            {header}
          </h2>
          <div className="Poppins-16-24-Regular lg:Poppins-18-28-Regular mb-8 text-center">
            {text}
          </div>
          <div>{renderPortfolios(portfolios)}</div>
          <div className="text-center mt-8">
            {dynamicComponentsBlock(button)}
          </div>
        </div>
      </div>
    );
  };

export default SbPortfoliosWithHeaderComponent;
