import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { ReviewStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import ReviewBG from '../../assets/images/review-bg.svg';

import './review.component.scss';

const SbReviewComponent: ReactDynamicComponent<ReviewStoryblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)}>
    <div className="review-component px-6 py-6 md:py-12 md:px-16 md:rounded-lg lg:rounded-xl text-white relative mb-16 -mx-4 mt-10 md:mt-14 md:mx-0">
      <div className="Lora-17-24-Italic md:Lora-24-32-Italic lg:Lora-34-56-Italic">
        {blok.text}
      </div>
      <div className="flex items-center mt-10">
        <div>
          <div className="Poppins-16-24-Bold md:Poppins-18-28-Bold">
            {blok.author}
          </div>
          <div className="Poppins-16-20-Regular md:Poppins-18-28-Regular">
            {blok.position}
          </div>
        </div>
      </div>
      <div className="review-container absolute top-0 left-0 w-full h-full -z-1 md:rounded-lg lg:rounded-xl" />
      <ReviewBG className="absolute bottom-0 right-0 -z-1 review-bg md:rounded-lg lg:rounded-xl" />
    </div>
  </div>
);

export default SbReviewComponent;
