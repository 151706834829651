import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ButtonStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import LinkComponent from '../../components/common/link/link.component';
import RightButton from '../../assets/images/RightButton.svg';
import DownArrow from '../../assets/images/arrow-down.svg';

import './button.component.scss';

const SbButtonComponent: ReactDynamicComponent<ButtonStoryblok> = ({
  blok,
}) => {
  const { text, appearance, style, link, isSubmit } = blok;

  let buttonStyle = 'rounded button-component';

  switch (style) {
    case 'large':
      buttonStyle += ' py-2 px-4 min-button-w-large Poppins-16-24-SemiBold';
      break;
    case 'small':
      buttonStyle += ' py-2 px-4 min-button-w-small Poppins-16-24-SemiBold';
      break;
    case 'non-bordered':
      buttonStyle += ' border-none Poppins-16-24-SemiBold';
      break;
    case 'link':
      buttonStyle += ' border-none Poppins-16-24-SemiBold';
      break;
    case 'main-banner':
      buttonStyle +=
        ' py-3 px-7 md:px-4 min-button-w-main-banner Poppins-16-24-SemiBold';
      break;
    case 'portfolio-link':
      buttonStyle += ' border-none Lora-17-26-Regular lg:Lora-21-36-Regular';
      break;
    default:
      buttonStyle += ' py-2 px-4 Poppins-16-24-SemiBold';
      break;
  }

  buttonStyle += ` style-${style}`;

  const buttonClasses = [buttonStyle].join(' ');

  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      {isSubmit ? (
        <button
          type={isSubmit ? 'submit' : 'button'}
          className={`${
            appearance
              ? `button-component__${appearance}`
              : 'button-component__default'
          }
            ${buttonClasses}`}
        >
          <div>{text}</div>
        </button>
      ) : (
        <LinkComponent link={link}>
          <button
            type={isSubmit ? 'submit' : 'button'}
            className={`${
              appearance
                ? `button-component__${appearance}`
                : 'button-component__default'
            }
            ${buttonClasses}`}
          >
            <div className="button-component__text-block">
              <div className="button-component__text">{text}</div>
              {style === 'link' ? (
                <RightButton
                  style={{
                    display: 'inline-block',
                    height: '1rem',
                    marginLeft: '.5rem',
                  }}
                />
              ) : null}
              {style === 'arrow-down' ? <DownArrow /> : null}
            </div>
          </button>
        </LinkComponent>
      )}
    </div>
  );
};

export default SbButtonComponent;
