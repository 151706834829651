import React from 'react';

export interface IBlogContext {
  blogs: any[];
  authors: { [key: string]: any };
  author?: string;
}

const BlogContext = React.createContext<IBlogContext>({
  blogs: [],
  authors: [],
  author: '',
});

export default BlogContext;
