import React, { useContext, useEffect, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import {
  CareerTestimonialsStoryblok,
  ButtonStoryblok,
  CareerTestimonialColumnStoryblok,
  TestimonialStoryblok,
} from '../../types';
import { ReactDynamicComponent } from '../common';

import './careerTestimonials.component.scss';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import Image from '../../components/image/adaptiveImage.component';
import Button from '../button/button.component';
import PageContext from '../pageContext';

import { generatedId } from '../../utils/uidGenerator';

const SbCareerTestimonialsBlock: ReactDynamicComponent<CareerTestimonialsStoryblok> =
  ({ blok }) => {
    const { title, label, items } = blok;
    const displayedItems = [...(items as [])];
    const [pageSize, setPageSize] = useState(3);
    const [testimonialsState, setTestimonialsState] = useState(1);
    const [showButton, setShowButton] = useState(true);
    const [content, setContent] = useState(
      [] as CareerTestimonialColumnStoryblok[]
    );

    const { screen } = useContext(PageContext);

    const buttonStyles: ButtonStoryblok = {
      text: 'Show more',
      style: 'large',
      appearance: 'white',
      component: 'button',
      _uid: generatedId(),
    };

    const loadContent = (size: number) => {
      setContent([]);

      displayedItems.map((column: CareerTestimonialColumnStoryblok) => {
        const newContent = column.items?.slice(0, size);
        // @ts-ignore
        return setContent((currentContent) => [...currentContent, newContent]);
      });
    };

    const buttonHandler = () => {
      items?.forEach((col) => {
        if (col.items && pageSize + 5 <= col.items.length) {
          loadContent(pageSize + 5);
          setPageSize(pageSize + 5);
          setTestimonialsState(testimonialsState + 1);
        } else {
          loadContent((col.items && col.items.length) || pageSize);
          setPageSize((col.items && col.items.length) || pageSize);
          setShowButton(false);
        }
      });
    };

    const renderTestimonials = (
      testimonials: CareerTestimonialColumnStoryblok[]
    ) => {
      if (screen?.isMobile) {
        return (
          <div
            className="career-testimonials__testimonial-column"
            key={generatedId()}
          >
            {testimonials[0].map((item: TestimonialStoryblok) => (
              <div
                className="career-testimonials__testimonial-item"
                key={item._uid}
              >
                {item.component === 'testimonial' ? (
                  dynamicComponentsBlock(item)
                ) : (
                  <Image
                    src={item.image?.filename || ''}
                    alt={item.image?.alt || 'TulaCo'}
                    processing="storyblock"
                  />
                )}
              </div>
            ))}
          </div>
        );
      }

      return testimonials?.map((column) => (
        <div
          className="career-testimonials__testimonial-column"
          key={generatedId()}
        >
          {column.map((item: TestimonialStoryblok) => (
            <div
              className="career-testimonials__testimonial-item"
              key={item._uid}
            >
              {item.component === 'testimonial' ? (
                dynamicComponentsBlock(item)
              ) : (
                <Image
                  src={item.image?.filename || ''}
                  alt={item.image?.alt || 'TulaCo'}
                  processing="storyblock"
                />
              )}
            </div>
          ))}
        </div>
      ));
    };

    useEffect(() => {
      loadContent(pageSize);

      const timer = setTimeout(() => {
        if (screen?.isMobile) {
          setPageSize(7);
          setShowButton(false);
        }
      }, 10);
      return () => clearTimeout(timer);
    }, [screen]);

    return (
      <div
        {...storyblokEditable(blok)}
        key={blok._uid}
        className="career-testimonials container"
      >
        <div className="career-testimonials__title Poppins-28-36-Bold lg:Poppins-42-56-Bold">
          {title}
        </div>
        <div className="career-testimonials__description Poppins-16-24-Regular lg:Poppins-18-28-Regular">
          {label}
        </div>
        <div className="career-testimonials__testimonials-wrapper">
          {renderTestimonials(content)}
        </div>
        <div
          className="career-testimonials__button-wrapper"
          style={{ display: `${showButton ? 'flex' : 'none'}` }}
        >
          <div
            className="career-testimonials__button-container"
            onClick={() => buttonHandler()}
          >
            <Button blok={buttonStyles} />
          </div>
        </div>
      </div>
    );
  };

export default SbCareerTestimonialsBlock;
