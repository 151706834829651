import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { render } from 'storyblok-rich-text-react-renderer-ts';
import { FormInputStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import 'filepond/dist/filepond.min.css';
import './formInput.component.scss';

registerPlugin(FilePondPluginFileEncode);

// type TFilePond = FilePondProps & { storeAsFile: boolean };
// const FilePondExt = (props: TFilePond) => <FilePond {...props} />;

const SbFormInput: ReactDynamicComponent<FormInputStoryblok> = ({ blok }) => {
  const [files, setFiles] = useState<any>([]);
  const [touched, setTouched] = useState<boolean>(false);

  // const updateFiles = (files: any) => {
  //   files.map((file: any) =>
  //     console.log('base64 value:\n', file.getFileEncodeBase64String())
  //   );
  // };

  const onKeyDown = (e: any) => {
    if (!/[0-9]/.test(e.key) && e.key !== '+' && e.keyCode !== 8) {
      e.preventDefault();
    }
  };

  const onBlurHandler = () => {
    setTouched(true);
  };

  const renderInput = (block: FormInputStoryblok) => {
    const { label, formName, labelCustom, required, errorLabel } = block;

    return (
      <div className={`input-wrapper${touched ? ' touched' : ''}`}>
        <label htmlFor={formName} className="block Poppins-16-24-Regular">
          {render(labelCustom)}
        </label>
        <input
          type={block.type}
          placeholder={
            required ? `${block.placeholder} *` : `${block.placeholder}`
          }
          id={formName}
          name={formName}
          required={required}
          onBlur={onBlurHandler}
          onKeyDown={block.type === 'tel' ? onKeyDown : () => {}}
        />
        <div className="error-label Poppins-12-16-Regular">{errorLabel}</div>
      </div>
    );
  };
  const renderCheckbox = (block: FormInputStoryblok) => {
    const { name, label, labelCustom, required, errorLabel } = block;
    return (
      <div className="input-wrapper__input-checkbox">
        <input
          className="checkbox"
          type={block.type}
          placeholder={block.placeholder}
          id={name}
          name={name}
          required={required}
          onBlur={onBlurHandler}
        />
        <label htmlFor={name} className="block Poppins-12-24-Regular">
          {block.label === '' ? render(labelCustom) : label}
          {block.label === '' ? <div className="red">*</div> : ''}
        </label>
        <div className="error-label Poppins-12-16-Regular">{errorLabel}</div>
      </div>
    );
  };
  const renderTextarea = (block: FormInputStoryblok) => {
    const { name } = block;
    const [val, setVal] = useState('');
    const textAreaRef = useRef() as MutableRefObject<HTMLTextAreaElement>;

    const resizeTextArea = () => {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    };

    useEffect(resizeTextArea, [val]);

    const onChange = (e: {
      target: { value: React.SetStateAction<string> };
    }) => {
      setVal(e.target.value);
    };

    return (
      <div className="input-wrapper ">
        <textarea
          ref={textAreaRef}
          onChange={onChange}
          name={name}
          id={name}
          placeholder={block.placeholder}
          rows={1}
        />
      </div>
    );
  };

  const renderFilesInput = (block: FormInputStoryblok) => {
    const { name, label } = block;
    // const labelEdit = label?.replace('Browse', '');
    const img =
      '<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M2.40194 17.6312C4.91958 20.1489 ' +
      '9.02155 20.1489 11.5313 17.6312L16.8255 12.3371C17.1549 12.0077 17.1549 11.4744 16.8255 ' +
      '11.145C16.4961 10.8156 15.9627 10.8156 15.6333 11.145L10.3392 16.4391C8.46468 18.3136 ' +
      '5.46076 18.3136 3.58625 16.4391C1.71174 14.5646 1.71174 11.5607 3.58625 9.68614L10.2059 ' +
      '3.06654C11.3431 1.92928 13.1706 1.92928 14.3078 3.06654C15.4451 4.21163 15.4451 6.03124 ' +
      '14.3078 7.17634L9.0137 12.4705C8.60586 12.8783 7.96272 12.8783 7.55488 12.4705C7.14704' +
      ' 12.0626 7.14704 11.4195 7.55488 11.0116L11.5235 7.04301C11.8529 6.71359 11.8529 6.18026 ' +
      '11.5235 5.85085C11.1941 5.52144 10.6608 5.52144 10.3313 5.85085L6.36272 9.81948C5.31174 ' +
      '10.8705 5.31174 12.6038 6.36272 13.6548C7.4137 14.7058 9.14703 14.7058 10.198 13.6548L15.4921 ' +
      '8.36065C17.2725 6.58026 17.2725 3.66261 15.4921 1.87438C14.598 0.980262 13.4294 0.533203' +
      ' 12.2529 0.533203C11.0764 0.533203 9.89998 0.980262 9.0137 1.87438L2.39409 8.49399C-0.123552 ' +
      '11.0116 -0.123552 15.1136 2.39409 17.6234L2.40194 17.6312Z" fill="white"/>\n' +
      '</svg>';
    const img2 =
      '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">\n' +
      '<path d="M7 1.5L7 12.5M12.5 7L1.5 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="bevel"/>\n' +
      '</svg>';

    const labelValue = `  <div class='icon-clip'> ${img}</div><div class='icon-clip-hover'> ${img2}</div>${label}`;

    return (
      <div className="input-wrapper input-files">
        <FilePond
          files={files}
          name={name}
          labelIdle={labelValue}
          allowMultiple
          onupdatefiles={setFiles}
          styleButtonRemoveItemPosition="right"
        />
      </div>
    );
  };

  const renderInputByType = (block: FormInputStoryblok, inputType: any) => {
    switch (inputType) {
      case 'textarea':
        return renderTextarea(blok);
      case 'checkbox':
        return renderCheckbox(block);
      case 'files':
        return renderFilesInput(blok);
      default:
        return renderInput(blok);
    }
  };

  return (
    <div {...storyblokEditable(blok)}>{renderInputByType(blok, blok.type)}</div>
  );
};

export default SbFormInput;
