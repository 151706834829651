import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { FooterStoryblok, ImageLinkItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './footer.component.scss';
import LinkedIn from '../../assets/images/linkedin.svg';
import Facebook from '../../assets/images/fb.svg';
import Instagram from '../../assets/images/instagram.svg';
import Twitter from '../../assets/images/twitter.svg';
import FooterBgImg from '../../assets/images/footerBg.svg';
import FooterBgLgImg from '../../assets/images/footerBgLg.svg';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbFooterComponent: ReactDynamicComponent<FooterStoryblok> = ({
  blok,
}) => {
  const renderIcon = (
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    iconData: ImageLinkItemStoryblok
  ) => (
    <div className="mr-7" key={iconData._uid}>
      <a href={iconData?.link?.url} target="_blank" rel="noreferrer">
        <Icon
          className="social-link"
          style={{
            height: '32px',
            width: '32px',
          }}
        />
      </a>
    </div>
  );

  const renderSocialIcons = () =>
    blok.socials?.map((socialIcon) => {
      switch (socialIcon.title) {
        case 'facebook':
          return renderIcon(Facebook, socialIcon);
        case 'instagram':
          return renderIcon(Instagram, socialIcon);
        case 'linkedin':
          return renderIcon(LinkedIn, socialIcon);
        case 'twitter':
          return renderIcon(Twitter, socialIcon);
        default:
          return <div className="mr-7" key={socialIcon._uid} />;
      }
    });

  return (
    <div {...storyblokEditable(blok)}>
      <footer className="footer Poppins-16-24-Regular md:Poppins-18-28-Regular text-blue bg-gray flex items-center">
        <div className="bgImage">
          <FooterBgImg />
        </div>
        <div className="bgLgImage">
          <FooterBgLgImg />
        </div>
        <div className="footer__wrapper flex justify-between container text-white py-10">
          <div className="left flex flex-col">
            {blok.photo && (
              <AdaptiveImage
                src={blok.photo.filename || ''}
                alt={blok.photo.alt || ''}
                className="rounded mb-4 w-32 h-6"
                processing="storyblock"
              />
            )}
            <p className="description Poppins-16-24-Regular mb-4">
              {blok.description}
            </p>
            <span className="copyright flex Poppins-16-24-Regular">
              {blok.copyright}
            </span>
          </div>
          <div className="right flex flex-col">
            <div className="contact flex lg:flex-col w-11/12 my-5 flex-col w-full md:Poppins-18-28-Regular Poppins-16-24-Regular">
              <div className="flex flex-row mt-0 items-center">
                <a
                  className="custom-underline custom-underline__small cursor-pointer"
                  href={`tel:${blok.phone}`}
                >
                  {blok.phone}
                </a>
                <span className="dot mx-2">.</span>
                <a
                  className="custom-underline custom-underline__small cursor-pointer"
                  href={`mailto:${blok.email}`}
                >
                  {blok.email}
                </a>
              </div>
              <div>
                <a
                  className="custom-underline custom-underline__small cursor-pointer"
                  href="https://www.google.com/maps/place/34%C2%B000'51.3%22N+118%C2%B029'41.8%22W/@34.0142491,-118.4954822,19z"
                >
                  {blok.address}
                </a>
              </div>
            </div>

            <div className="social-icons flex mb-6">{renderSocialIcons()}</div>
            <span className="copyright flex Poppins-16-24-Regular md:hidden">
              {blok.copyright}
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default SbFooterComponent;
