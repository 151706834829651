import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { PageDetailsStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './pageDetails.component.scss';

const SbPageDetailsComponent: ReactDynamicComponent<PageDetailsStoryblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)}>
    <div className="page-details flex justify-start text-blue mt-6 mb-10 mx-0 lg:mt-16 lg:mb-6">
      <div className="page-details__content w-full">
        <h1 className="page-details__title Poppins-28-36-Bold lg:Poppins-42-56-Bold">
          {blok.text}
        </h1>

        {blok.description && (
          <div className="page-details__description Lora-17-24-Italic md:Lora-24-32-Italic lg:Lora-24-32-Italic mt-1 lg:mt-6">
            {blok.description}
          </div>
        )}
        {blok.showLine && <div className="hidden md:block red-line" />}
      </div>
    </div>
  </div>
);

export default SbPageDetailsComponent;
