import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { KeyPointItemStoryblok } from '../../types';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbKeyPointItem: ReactDynamicComponent<KeyPointItemStoryblok> = ({
  blok,
}) => {
  const { image } = blok;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className="point-item">
        {image?.length
          ? image.map((img: any) => (
              <AdaptiveImage
                src={img.image?.filename || ''}
                mobilesrc={img.mobileImage?.filename}
                tabletsrc={img.tabletImage?.filename}
                alt={img.image?.alt || img.mobileImage?.alt || ''}
                processing={img.processing}
                key={img._uid}
              />
            ))
          : null}
      </div>
    </div>
  );
};
export default SbKeyPointItem;
