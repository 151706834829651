enum HostName {
  QA = 'www-qa.tula.co',
  PROD = 'tula.co',
  LOCALHOST = 'localhost',
}

export const getEnvironment = () => {
  if (window) {
    switch (window?.location?.hostname) {
      case HostName.QA:
        return 'QA';
      case HostName.PROD:
        return 'PROD';
      case HostName.LOCALHOST:
        return 'LOCALHOST';
      default:
        return undefined;
    }
  } else {
    return undefined;
  }
};
