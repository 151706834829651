// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';

import './hero-banner2.component.scss';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { HeroBanner2Storyblok } from '../../types';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbHeroBannerSolution: ReactDynamicComponent<HeroBanner2Storyblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)}>
    <div className="solutions-bg">
      {blok.background && blok.background[0] && blok.background[0].image && (
        <AdaptiveImage
          src={blok.background[0].image.filename}
          tabletsrc={blok.background[0].tabletImage?.filename}
          mobilesrc={blok.background[0].mobileImage?.filename}
          processing={blok.background[0].image.alt ?? ''}
        />
      )}
    </div>
    <div className="solutions-hero">
      <div className="solutions-block">
        <h1 className="solutions-header ">{blok.header}</h1>
        {blok.image && blok.image[0] && blok.image[0].image && (
          <AdaptiveImage
            src={blok.image[0].image.filename}
            tabletsrc={blok.image[0].tabletImage?.filename}
            mobilesrc={blok.image[0].mobileImage?.filename}
            processing={blok.image[0].image.alt ?? ''}
          />
        )}
      </div>
      <div className="solution-description">{blok.description}</div>
    </div>
  </div>
);
export default SbHeroBannerSolution;
