import { FC } from 'react';
import { SbEditableContent } from 'storyblok-react';

import SbBenefitsComponent from './benefits/benefits.component';
import SbBenefitsItem from './benefits-item/benefits-item.component';
import SbButtonComponent from './button/button.component';
import SbCareerTestimonialsBlock from './careerTestimonials/careerTestimonials.component';
import SbCaseStudiesDetailsComponent from './caseStudiesDetails/caseStudiesDetails.component';
import SbCommonText from './commonText/common-text.component';
import SbContactForm from './contactForm/contactForm.component';
import SbCulture from './culture/culture.component';
import SbCultureItem from './culture-item/culture-item.component';
import SbFooterComponent from './footer/footer.component';
import SbFormInput from './formInput/formInput.component';
import SbFormSection from './formSection/formSection.component';
import SbHeaderComponent from './header/header.component';
import SbHeroBanner from './hero-banner/hero-banner.component';
import SbCodeSnippet from './codeSnippet/codeSnippet.component';
import SbCodeSnippetCustom from './codeSnippetCustom/codeSnippetCustom.component';
import SbImageGallery2Component from './imageGallery2/imageGallery2.component';
import SbImageGalleryComponent from './imageGallery/imageGallery.component';
import SbImageGalleryItemComponent from './imageGalleryItem/imageGalleryItem.component';
import SbImageGallerySimpleComponent from './imageGallerySimple/imageGallerySimple.component';
import SbImageGalleryCarouselComponent from './imageGalleryCarousel/imageGalleryCarousel.component';
import SbImageLinkItem from './image-link-item/image-link-item.component';
import SbImageWithLink from './imageWithLink/imageWithLink.component';
import SbImageWithText from './imageWithText/imageWithText.component';
import SbImageWithText2 from './imageWithText2/imageWithText2.component';
import SbJobOpenings from './jobOpenings/jobOpenings.component';
import SbJobOpeningsWithText from './jobOpeningsWithText/jobOpeningsWithText.component';
import SbLocationsComponent from './locations/locations.component';
import SbMainBanner from './mainBanner/mainBanner.component';
import SbPageDetailsComponent from './pageDetails/pageDetails.component';
import SbPortfolioDefaultComponent from './portfolioDefault/portfolioDefault.component';
import SbPortfolioItemComponent from './portfolioItem/portfolioItem.component';
import SbPortfolioWithTestimonialComponent from './portfolioWithTestimonial/portfolioWithTestimonial.component';
import SbPortfoliosWithHeaderComponent from './portfoliosWithHeader/portfoliosWithHeader.component';
import SbPortfoliosWithStatsComponent from './portfoliosWithStats/portfoliosWithStats.component';
import SbPostItem from './postMainContent/postMainContent.component';
import SbRecentArticles from './recentArticles/recent-articles.component';
import SbReviewComponent from './review/review.component';
import SbSelectedArticles from './selectedArticles/selected-articles.component';
import SbSocialGallery from './social-gallery/social-gallery.component';
import SbStatisticsComponent from './statistics/statistics.component';
import SbTableOfContents from './table-of-contents/table-of-contents.component';
import SbTeamComponent from './team/team.component';
import SbTeamItemComponent from './team/components/teamItem/teamItem.component';
import SbTestimonialComponent from './testimonial/testimonial.component';
import SbTestimonialsWithText from './testimonialsWithText/testimonialsWithText.component';
import SbTextBlock from './textBlock/textBlock.component';
import SbTextBlock2 from './textBlock2/textBlock2.component';
import SbTextItemComponent from './textItem/textItem.component';
import SbVacanciesBlock from './vacancies/vacancies.component';
import SbValuesBlock from './values/values.component';
import SbCookiesConsentComponent from './cookiesConsent/cookiesConsent.component';
import SbBlockWithTilesComponent from './blockWithTiles/blockWithTiles.component';
import SbFaqComponent from './faq/faq.component';
import SbCallAction from './callToActionBlock/callToActionBlock.component';
import SbHeroBannerSolution from './heroBanner2/hero-banner2.component';
import SbAdvantages from './advantages/advantages.component';
import SbAdvantagesItem from './advantages-item/advantages-item.component';
import SbSeparator from './separator/separator.component';
import SbKeyPoints from './key-points/keyPoints.component';
import SbKeyPointItem from './keyPoint-item/keyPointsItem.component';

export interface CommonProps<T extends SbEditableContent> {
  blok: T;
  index?: number;
}

export type ReactDynamicComponent<T extends SbEditableContent> = FC<
  CommonProps<T>
>;

export const Components: Record<string, ReactDynamicComponent<any>> = {
  'contact-form': SbContactForm,
  'form-input': SbFormInput,
  'form-section': SbFormSection,
  advantages: SbAdvantages,
  advantagesItem: SbAdvantagesItem,
  heroBanner2: SbHeroBannerSolution,
  HeroBanner: SbHeroBanner,
  benefits: SbBenefitsComponent,
  benefitsItem: SbBenefitsItem,
  button: SbButtonComponent,
  careerTestimonials: SbCareerTestimonialsBlock,
  caseStudiesDetails: SbCaseStudiesDetailsComponent,
  codeSnippet: SbCodeSnippet,
  codeSnippetCustom: SbCodeSnippetCustom,
  commonText: SbCommonText,
  culture: SbCulture,
  cultureItem: SbCultureItem,
  footer: SbFooterComponent,
  header: SbHeaderComponent,
  cookiesConsent: SbCookiesConsentComponent,
  imageGallery2: SbImageGallery2Component,
  imageGallery: SbImageGalleryComponent,
  imageGalleryItem: SbImageGalleryItemComponent,
  imageGallerySimple: SbImageGallerySimpleComponent,
  imageLinkItem: SbImageLinkItem,
  imageWithLink: SbImageWithLink,
  imageWithText2: SbImageWithText2,
  imageWithText: SbImageWithText,
  jobOpenings: SbJobOpenings,
  jobOpeningsWithText: SbJobOpeningsWithText,
  keyPoints: SbKeyPoints,
  keyPointItem: SbKeyPointItem,
  locations: SbLocationsComponent,
  mainBanner: SbMainBanner,
  callToActionBlock: SbCallAction,
  pageDetails: SbPageDetailsComponent,
  portfolioDefault: SbPortfolioDefaultComponent,
  portfolioItem: SbPortfolioItemComponent,
  portfolioWithTestimonial: SbPortfolioWithTestimonialComponent,
  portfoliosWithHeader: SbPortfoliosWithHeaderComponent,
  portfoliosWithStats: SbPortfoliosWithStatsComponent,
  postItem: SbPostItem,
  recentArticles: SbRecentArticles,
  review: SbReviewComponent,
  selectedArticles: SbSelectedArticles,
  separator: SbSeparator,
  socialGalery: SbSocialGallery,
  statistics: SbStatisticsComponent,
  tableOfContents: SbTableOfContents,
  team: SbTeamComponent,
  teamItem: SbTeamItemComponent,
  testimonial: SbTestimonialComponent,
  testimonialsWithText: SbTestimonialsWithText,
  textBlock2: SbTextBlock2,
  textBlock: SbTextBlock,
  textItem: SbTextItemComponent,
  vacancies: SbVacanciesBlock,
  values: SbValuesBlock,
  blockWithTiles: SbBlockWithTilesComponent,
  faq: SbFaqComponent,
  imageGalleryCarousel: SbImageGalleryCarouselComponent,
};
