import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { PortfolioDefaultStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import Link from '../../components/common/link/link.component';

import './portfolioDefault.component.scss';

const SbPortfolioDefaultComponent: ReactDynamicComponent<PortfolioDefaultStoryblok> =
  ({ blok }) => (
    <div {...storyblokEditable(blok)}>
      <div
        className={`flex-col md:flex-row ${
          blok.portfilio && blok.portfilio.length > 1 ? 'flex' : ''
        }`}
      >
        {blok.portfilio?.map((item, index) => (
          <Link className="block w-full" link={item.link} key={item._uid}>
            <div
              className={`mt-2 ${
                index === 0 && blok.portfilio && blok.portfilio?.length > 1
                  ? 'md:mr-1'
                  : ''
              } ${index === 1 ? 'md:ml-1' : ''}`}
            >
              {dynamicComponentsBlock(item)}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );

export default SbPortfolioDefaultComponent;
