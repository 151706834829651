import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import Slider from 'react-slick';

import { ImageGalleryCarouselStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './imageGalleryCarousel.component.scss';

const SbImageGalleryComponent: ReactDynamicComponent<ImageGalleryCarouselStoryblok> =
  ({ blok }) => {
    const settings = {
      slidesToShow: 6,
      slidesToScroll: 1,
      speed: 6000,
      cssEase: 'linear',
      swipeToSlide: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 0,
      dots: false,
      arrows: false,
      pauseOnHover: false,
      focusOnClick: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1099,
          settings: {
            slidesToShow: 4,
          },
        },
      ],
    };

    return (
      <div {...storyblokEditable(blok)}>
        <div className="image-carousel">
          <Slider {...settings}>
            {blok.images?.map((item) => (
              <div className="image-wrapper">
                <img
                  className="static"
                  key={item._uid}
                  src={item.imageNormal && item.imageNormal[0].image?.filename}
                  alt={
                    (item.imageNormal && item.imageNormal[0].image?.alt) || ''
                  }
                />
                <img
                  className="hovered"
                  src={
                    item.imageHovered && item.imageHovered[0].image?.filename
                  }
                  alt={
                    (item.imageHovered && item.imageHovered[0].image?.alt) || ''
                  }
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  };

export default SbImageGalleryComponent;
