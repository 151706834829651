import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import CountUp from 'react-countup';

import { StatisticsStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './statistics.component.scss';

const SbStatisticsComponent: ReactDynamicComponent<StatisticsStoryblok> = ({
  blok,
}) => (
  <div {...storyblokEditable(blok)}>
    <div className="stats__title Poppins-14-20-Bold md:Poppins-16-24-Bold mb-3 lg:hidden">
      {blok.title}
    </div>
    <div className="stats__content lg:grid grid-cols-2 gap-11">
      <div className="hidden lg:block text-red Poppins-24-34-Bold">
        {blok.title}
      </div>
      {blok.items?.map((item) => (
        <div className="grid grid-cols-3 my-4 lg:m-0 lg:block md:pr-16">
          <div className="stats__count Lora-40-40-Bold lg:Lora-72-72-Bold col-span-1 md:text-red flex items-end mb-3">
            <CountUp
              start={0}
              end={parseFloat(item.number || '0')}
              duration={2}
              decimals={item?.number?.includes('.') ? 1 : 0}
            />
            {item.suffix && (
              <div className="Lora-28-40-Bold lg:Lora-40-40-Bold">
                {item.suffix}
              </div>
            )}
          </div>
          <div className="stats__description Poppins-14-20-Regular md:Poppins-16-24-Regular col-span-2">
            {item.text}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default SbStatisticsComponent;
