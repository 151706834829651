import React, { useEffect, useRef, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';

import { render } from 'storyblok-rich-text-react-renderer-ts';

import { ImageWithText2Storyblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import SimpleSliderComponent from '../../components/common/simpleSlider/simpleSlider.component';

import './imageWithText2.component.scss';

const SbImageWithText2: ReactDynamicComponent<ImageWithText2Storyblok> = ({
  blok,
}) => {
  const {
    header,
    Sublabel,
    button,
    description,
    image,
    imagePosition,
    imagePositionMobile,
    theme,
  } = blok;
  const [isRender, setIsRender] = useState(false);

  const target = useRef() as React.RefObject<HTMLDivElement>;
  const intersectionObserverOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  };

  const intersectionObserverCallback = (
    entries: { isIntersecting: boolean }[]
  ) => {
    if (entries[0].isIntersecting) {
      setIsRender(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      intersectionObserverCallback,
      intersectionObserverOptions
    );

    if (target.current) {
      observer.observe(target.current);
    }
  }, []);

  const renderAnimatedDescription = () => (
    <div
      className={`animated-description fadeInBottom lg:Poppins-20-30-Regular
    ${imagePositionMobile === 'top' ? 'build' : ''}
    `}
    >
      {render(description)}
    </div>
  );

  return (
    <div
      className={`image-with-text-2 text-blue flex justify-center
      ${theme === 'light' ? 'bg-white py-8' : 'bg-gray-100 py-20'}
      ${imagePositionMobile === 'top' ? 'lg:mb-40' : 'lg:mb-5'}
      lg:gap-10 relative mx-4 mb-0 mt-2 lg:mx-0`}
      ref={target}
      {...storyblokEditable(blok)}
    >
      <div
        className={`image-with-text-container text-blue flex ${
          imagePosition === 'left' ? 'lg:flex-row-reverse' : 'lg:flex-row'
        }
       ${imagePositionMobile === 'top' ? 'flex-col-reverse' : 'flex-col'}


      lg:gap-10 relative mx-4 mb-0 lg:mb-0 lg:mx-0 sm: mx-8`}
      >
        <div
          className={`w-11/12 lg:w-7/12 py-4 rounded-t-lg md:rounded-l-lg flex lg:pt-0 -mt-20 md:mt-0 z-30
        ${imagePositionMobile === 'top' ? 'lg:w-full' : 'lg:w-7/12'}
        `}
        >
          <div>
            <div className="sublabel">{Sublabel}</div>
            <h2
              className={`image-with-text-header mb-7 w-11/12 md:w-10/12 rounded-tr-lg pt-2.5 md:pt-0
            ${imagePositionMobile === 'top' ? 'md:w-10/12' : 'md:w-11/12'}
            `}
            >
              {header}
            </h2>
            {isRender && renderAnimatedDescription()}
            <a
              href={button && `/${button[0].link?.cached_url}/`}
              className="learn-btn"
            >
              {button && button[0].text}
            </a>
          </div>
        </div>
        <div
          className={`static lg:flex flex-row  overflow-hidden rounded-r-lg rounded-t-lg image-block
         ${
           imagePositionMobile === 'top'
             ? 'top-image mt-5 lg:w-9/12'
             : 'mt-12 lg:w-457/1000'
         }`}
        >
          {image && <SimpleSliderComponent images={image} />}
        </div>
      </div>
    </div>
  );
};

export default SbImageWithText2;
