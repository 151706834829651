import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { PortfolioItemStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import Link from '../../components/common/link/link.component';

import './portfolioItem.component.scss';

const SbPortfolioItemComponent: ReactDynamicComponent<PortfolioItemStoryblok> =
  ({ blok }) => {
    const { mobileTemplate, mobileVerticalAlignment } = blok;

    const displayImage = !!blok.image?.filename && !blok.imageAsBackground;

    const templateMinHeight =
      blok.template === 'big' ? 'portfolio-item__min-h-portfolio-big' : '';
    const contentAlignment =
      blok.contentAlignment === 'right' ? 'justify-end' : 'justify-start';
    const portfolioClasses = `portfolio-item__content flex md:flex-row rounded-lg ${
      displayImage
        ? 'flex-col-reverse portfolio-item__background-gradient portfolio-item__item-with-image px-12 py-16'
        : `${
            blok.imageAsBackground ? '' : 'portfolio-item__background-default'
          } portfolio-item__item-no-image p-5 md:p-8 ${templateMinHeight} h-full`
    } ${contentAlignment}`;

    return (
      <div {...storyblokEditable(blok)}>
        <Link
          className={`portfolio-item mobile-template-${mobileTemplate} ${
            blok.imageAsBackground ? 'block w-full' : ''
          } mobile-valign-${mobileVerticalAlignment}`}
          link={blok.link}
        >
          <div className="portfolio-item__background">
            {blok.adaptiveImage?.map((image) => (
              <AdaptiveImage
                key={image._uid}
                src={image.image?.filename || ''}
                mobilesrc={image.mobileImage?.filename}
                alt={image.image?.alt || image.mobileImage?.alt || ''}
                processing={image.processing}
              />
            ))}
          </div>

          <div className={portfolioClasses}>
            <div
              className={`text-blue ${
                blok.contentAlignment === 'default' ? 'w-full' : 'md:w-5/12'
              }`}
            >
              <div className="Poppins-12-20-Medium lg:Poppins-16-24-SemiBold text-red">
                {blok.title}
              </div>
              <div className="Poppins-18-28-Bold lg:Poppins-24-36-Bold">
                {blok.snippet}
              </div>
              <div className="Lora-17-24-Italic mt-4">{blok.description}</div>
              <div className="Poppins-12-16-Regular lg:Poppins-16-24-Regular flex">
                {blok.tags?.map((tag, i, arr) => (
                  <>
                    <div>{tag.text}</div>
                    {i + 1 !== arr.length && <div className="mx-1">•</div>}
                  </>
                ))}
              </div>
            </div>
            {displayImage && (
              <div className="md:w-6/12">
                <AdaptiveImage
                  src={blok.image?.filename || ''}
                  alt={blok.image?.alt || ''}
                  processing={blok?.processing}
                />
              </div>
            )}
          </div>
        </Link>
      </div>
    );
  };

export default SbPortfolioItemComponent;
