import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { v4 as uuid } from 'uuid';
import { CaseStudiesDetailsStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import LinkComponent from '../../components/common/link/link.component';

import './caseStudiesDetails.component.scss';

const SbCaseStudiesDetailsComponent: ReactDynamicComponent<CaseStudiesDetailsStoryblok> =
  ({ blok }) => {
    const renderLeftSide = () => (
      <div className="w-11/12 md:w-4/12 text-left mx-auto">
        <div className="my-4">
          <div className="text-grey-2 Poppins-12-20-Medium">CLIENT</div>
          <LinkComponent link={blok.websiteLink}>
            <div className="Poppins-14-20-Regular md:Poppins-16-24-Regular">
              {blok.projectName}
            </div>
          </LinkComponent>
        </div>
        <div className="my-4">
          <div className="text-grey-2 Poppins-12-20-Medium">PLATFORMS</div>
          <div className="flex Poppins-14-20-Regular md:Poppins-16-24-Regular">
            {blok.platforms?.map((tag, i, arr) => (
              <React.Fragment key={uuid()}>
                <div>{tag.text}</div>
                {i + 1 !== arr.length && <span className="mx-1">•</span>}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey-2 Poppins-12-20-Medium">SCOPE OF WORK</div>
          <div className="Poppins-14-20-Regular md:Poppins-16-24-Regular">
            {blok.scope?.split('\n')?.map((s) => (
              <div key={uuid()}>{s}</div>
            ))}
          </div>
        </div>
        <div className="my-4">
          <div className="text-grey-2 Poppins-12-20-Medium mb-2">
            CORE TECHNOLOGIES
          </div>
          <div className="flex">
            {blok.technologies?.map(
              (tech, i) =>
                tech.image?.filename && (
                  <AdaptiveImage
                    key={uuid()}
                    src={tech.image?.filename || ''}
                    alt={tech.image?.alt || ''}
                    title={tech.image?.title || ''}
                    width="32"
                    className={`${i === 0 ? 'mr-2' : 'mx-2'}`}
                    processing={tech?.processing}
                  />
                )
            )}
          </div>
          <hr className="block lg:hidden my-4" />
        </div>
      </div>
    );

    const renderRightSide = () => (
      <div className="right-side-desktop-case w-11/12 md:w-8/12 bg-white pl-10 pt-10 rounded-tr-xl md:rounded-tl-xl md:rounded-tr-none">
        <div className="flex text-red Poppins-12-20-Medium md:Poppins-16-24-SemiBold">
          {blok.tags?.map((tag, i, arr) => (
            <React.Fragment key={uuid()}>
              <div>{tag.text}</div>
              {i + 1 !== arr.length && <span className="mx-1">•</span>}
            </React.Fragment>
          ))}
        </div>
        <h1 className="Poppins-24-36-Bold md:Poppins-42-56-Bold lg:Poppins-50-64-Bold">
          {blok.header}
        </h1>
        <div className="Lora-20-26-Regular md:Lora-24-32-Italic lg:Lora-28-26-Italic mt-2 mb-4">
          {blok.label}
        </div>
        <hr className="my-4" />

        <div className="right-side-bottom flex flex-col lg:flex-row mb-16">
          <div className="Lora-17-26-Regular md:Lora-18-34-Regular lg:Lora-21-36-Regular w-11/12 lg:w-full text-left mx-auto">
            {blok.description}
          </div>
        </div>
      </div>
    );

    const renderMobileView = () => (
      <div>
        <div className="mobile-view-content w-12/12 bg-white pl-3 pt-3 md:p-10 rounded-tr-xl md:rounded-tl-xl md:rounded-tr-none">
          <div className="flex text-red Poppins-12-20-Medium md:Poppins-16-24-SemiBold bg-white">
            {blok.tags?.map((tag, i, arr) => (
              <React.Fragment key={uuid()}>
                <div>{tag.text}</div>
                {i + 1 !== arr.length && <span className="mx-1">•</span>}
              </React.Fragment>
            ))}
          </div>
          <h1 className="Poppins-28-36-Bold md:Poppins-42-56-Bold">
            {blok.header}
          </h1>
          <div className="Lora-17-24-Italic md:Lora-24-32-Italic mt-2 mb-4">
            {blok.label}
          </div>
        </div>
        <hr className="my-4" />
        <div className="w-11/12 text-left mx-auto flex flex-col md:flex-row md:justify-between md:px-10">
          <div>
            <div>
              <div className="text-grey-2 Poppins-12-20-Medium">CLIENT</div>
              <LinkComponent link={blok.websiteLink}>
                <div className="Poppins-14-20-Regular md:Poppins-16-24-Regular">
                  {blok.projectName}
                </div>
              </LinkComponent>
            </div>
            <div className="my-4">
              <div className="text-grey-2 Poppins-12-20-Medium">PLATFORMS</div>
              <div className="flex Poppins-14-20-Regular md:Poppins-16-24-Regular">
                {blok.platforms?.map((tag, i, arr) => (
                  <React.Fragment key={uuid()}>
                    <div>{tag.text}</div>
                    {i + 1 !== arr.length && <span className="mx-1">•</span>}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="my-4 md:hidden">
              <div className="text-grey-2 Poppins-12-20-Medium">
                SCOPE OF WORK
              </div>
              <div className="Poppins-14-20-Regular md:Poppins-16-24-Regular">
                {blok.scope?.split('\n')?.map((s) => (
                  <div key={uuid()}>{s}</div>
                ))}
              </div>
            </div>
            <div className="mt-4">
              <div className="text-grey-2 Poppins-12-20-Medium mb-2">
                CORE TECHNOLOGIES
              </div>
              <div className="flex">
                {blok.technologies?.map(
                  (tech, i) =>
                    tech.image?.filename && (
                      <AdaptiveImage
                        key={uuid()}
                        src={tech.image?.filename || ''}
                        alt={tech.image?.alt || ''}
                        title={tech.image?.title || ''}
                        width="32"
                        className={`${i === 0 ? 'mr-2' : 'mx-2'}`}
                        processing={tech?.processing}
                      />
                    )
                )}
              </div>
            </div>
          </div>
          <div className="hidden md:flex">
            <div className="">
              <div className="text-grey-2 Poppins-12-20-Medium">
                SCOPE OF WORK
              </div>
              <div className="Poppins-14-20-Regular md:Poppins-16-24-Regular">
                {blok.scope?.split('\n')?.map((s) => (
                  <div key={uuid()}>{s}</div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <hr className="block my-4" />
        <div className="right-side-bottom flex flex-col lg:flex-row mb-5 md:mb-14 md:px-10">
          <div className="Lora-17-26-Regular md:Lora-18-34-Regular w-11/12 text-left mx-auto">
            {blok.description}
          </div>
        </div>
      </div>
    );

    return (
      <div {...storyblokEditable(blok)} key={blok._uid}>
        <div className="case-study-details -mx-4 md:-mx-12 lg:mx-0">
          {blok.image2 && (
            <AdaptiveImage
              src={blok.image2[0].image?.filename || ''}
              mobilesrc={blok.image2[0].mobileImage?.filename}
              alt={
                blok.image2[0].image?.alt ||
                blok.image2[0].mobileImage?.alt ||
                ''
              }
              width="2000"
              className="w-full relative -z-1 details-img object-cover lg:rounded-bl-lg"
              processing={blok?.processing}
            />
          )}

          <div className="flex flex-row lg:justify-end hidden lg:flex">
            {renderLeftSide()}
            {renderRightSide()}
          </div>

          <div className="flex flex-col lg:hidden">{renderMobileView()}</div>
        </div>
      </div>
    );
  };

export default SbCaseStudiesDetailsComponent;
