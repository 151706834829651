import React, { useState } from 'react';

import { FaqStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import Plus from '../../assets/images/plus.svg';

import './faq.component.scss';

const SbBenefitsComponent: ReactDynamicComponent<FaqStoryblok> = ({ blok }) => {
  const [activeItemIndex, setActiveItemsIndex] = useState(null);

  const onItemClick = (index: never) => {
    if (index === activeItemIndex) {
      setActiveItemsIndex(null);
    } else {
      setActiveItemsIndex(index);
    }
  };

  const isActive = (index: never) => activeItemIndex === index;

  return (
    <div className="faq">
      <div className="faq__container">
        <h2 className="Poppins-24-36-Bold md:Poppins-32-44-Bold lg:Poppins-42-56-Bold mt-16 mb-8">
          {blok.title}
        </h2>
        {blok.items?.map((item, index) => (
          <div key={item._uid} className="faq__element flex flex-col">
            <div className="flex items-center justify-between">
              <h3 className="Poppins-16-24-Bold mt-2 text-left">
                {item.title}
              </h3>
              <div onClick={() => onItemClick(index as never)}>
                <Plus />
              </div>
            </div>
            {isActive(index as never) && (
              <div className="info Poppins-17-26-Regular md:Poppins-18-30-Regular lg:Poppins-21-36-Regular text-left mt-2">
                {item.label}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SbBenefitsComponent;
