import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { AdvantagesItemStoryblok } from '../../types';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import './advantages-item.component.scss';

const SbAdvantagesItem: ReactDynamicComponent<AdvantagesItemStoryblok> = ({
  blok,
}) => {
  const { icon } = blok;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className="advantages-item">
        {icon?.length
          ? icon.map((img: any) => (
              <AdaptiveImage
                src={img.image?.filename || ''}
                mobilesrc={img.mobileImage?.filename}
                alt={img.image?.alt || img.mobileImage?.alt || ''}
                processing={img.processing}
                key={img._uid}
              />
            ))
          : null}
      </div>
    </div>
  );
};
export default SbAdvantagesItem;
