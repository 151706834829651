import React, { useContext, useEffect, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { PostStoryblok, SelectedArticlesStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import BlogContext from '../blogContext';

import './selected-articles.component.scss';

const timeToSlideChange = 6000;

const SbSelectedArticles: ReactDynamicComponent<SelectedArticlesStoryblok> = ({
  blok,
}) => {
  const { articles } = blok;
  const { blogs } = useContext(BlogContext);
  const selectedArticles = blogs.filter((item: any) =>
    articles?.includes(item.uuid)
  );
  const articleLength = selectedArticles?.length;

  const [activeIndex, setActiveIndex] = useState(0);

  const RenderSlide = (article: any, active: boolean = false) => {
    const articleContent: PostStoryblok = article.content.body.filter(
      (item: any) => item.component === 'postItem'
    )[0];

    return (
      <div
        className={`selected-articles__slide${active ? ' active' : ''}`}
        key={article.uuid}
      >
        <div className="selected-articles__slide-image">
          <img
            src={
              (articleContent.coverForSelection &&
                articleContent.coverForSelection?.length &&
                articleContent.coverForSelection[0].image?.filename) ||
              ''
            }
            alt={
              (articleContent.coverForSelection &&
                articleContent.coverForSelection?.length &&
                articleContent.coverForSelection[0].image?.alt) ||
              ''
            }
          />
        </div>
        <div className="selected-articles__slide__text-wrapper">
          <div className="selected-articles__slide__text-container">
            <h1 className="selected-articles__slide__title">
              {articleContent.title}
            </h1>
            <p className="selected-articles__slide__description">
              {articleContent.mediumDescription}
            </p>
            <a
              className="selected-articles__button"
              href={`/${article.full_slug}/`}
            >
              Read more
            </a>
          </div>
        </div>
      </div>
    );
  };

  const RenderButtons = () => (
    <ul className="selected-articles__switches">
      {selectedArticles.map((item, index) => (
        <li
          className={`${index === activeIndex ? 'active' : ''}`}
          key={String(index)}
        >
          <button onClick={() => setActiveIndex(index)} type="button">
            &nbsp;
          </button>
        </li>
      ))}
    </ul>
  );

  const ShiftSlide = () => {
    setActiveIndex((activeIndex + 1) % articleLength);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      ShiftSlide();
    }, timeToSlideChange);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="selected-articles" {...storyblokEditable(blok)}>
      <div className="selected-articles__wrapper">
        <div className="selected-articles__slider-wrapper">
          <div className="selected-articles__slider-container">
            {selectedArticles.map((article, index) =>
              RenderSlide(article, index === activeIndex)
            )}
          </div>
        </div>
        <div className="selected-articles__nav">{RenderButtons()}</div>
      </div>
    </div>
  );
};

export default SbSelectedArticles;
