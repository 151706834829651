import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { render } from 'storyblok-rich-text-react-renderer-ts';

import { ImageWithTextStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import SimpleSliderComponent from '../../components/common/simpleSlider/simpleSlider.component';

import './imageWithText.component.scss';

const SbImageWIthText: ReactDynamicComponent<ImageWithTextStoryblok> = ({
  blok,
}) => {
  const { header, description, button, images, background } = blok;

  return (
    <div
      className="image-with-text text-blue flex flex-row relative mx-0 mb-28 lg:mb-32"
      {...storyblokEditable(blok)}
    >
      <div
        className="image-with-text__text-block flex flex-col justify-around w-12/12 md:w-7/12 p-4 md:p-14 rounded-t-lg md:rounded-l-lg"
        style={{
          backgroundImage: `url(${
            (background && background[0]?.image?.filename) || ''
          })`,
        }}
      >
        <h2 className="image-with-text__header lg:Poppins-24-36-Bold md:Poppins-28-36-Bold pb-3">
          {header}
        </h2>
        <div className="image-with-text__description Poppins-16-24-Regular lg:Poppins-18-28-Regular pb-14 md:pb-8">
          {render(description)}
        </div>
        <div className="image-with-text__footer-button absolute bottom-0 lg:static w-full md:w-auto">
          {dynamicComponentsBlock(button)}
        </div>
      </div>

      <div className="image-with-text__images lg:flex flex-row w-12/12 md:w-5/12 overflow-hidden rounded-r">
        {images && <SimpleSliderComponent images={images} />}
      </div>
    </div>
  );
};

export default SbImageWIthText;
