import { IPluginRefObject } from 'gatsby';
import StoryblokClient, { StoryblokComponent } from 'storyblok-js-client';
import config from '../../gatsby-config';
import { getEnvironment } from './helpers';

const sbConfig: IPluginRefObject | undefined = config?.plugins?.find(
  (item): item is IPluginRefObject =>
    typeof item === 'object' && item.resolve === 'gatsby-source-storyblok'
);

declare global {
  class StoryblockBridgeV2 {
    constructor(options: { resolveRelations: string[] });
    on<S extends StoryblokComponent<string> = any>(
      event:
        | 'customEvent'
        | 'published'
        | 'input'
        | 'change'
        | 'unpublished'
        | 'enterEditmode'
        | string[],
      callback: (payload?: StoryblokEventPayload<S>) => void
    ): void;
  }
  interface Window {
    StoryblokBridge: typeof StoryblockBridgeV2;
  }
}
class StoryblokService {
  devMode: boolean;

  token?: string;

  client: StoryblokClient;

  query: string;

  constructor() {
    this.devMode = false;
    this.token = sbConfig?.options?.accessToken as string;
    this.client = new StoryblokClient({
      accessToken: this.token,
      cache: {
        clear: 'auto',
        type: 'memory',
      },
    });
    this.query = '';
  }

  getCacheVersion() {
    return this.client.cacheVersion;
  }

  get(slug: string, params?: Record<string, string | number>) {
    const parameters = params || {
      version: getEnvironment() !== 'PROD' ? 'draft' : '',
    };

    if (
      this.getQuery('_storyblok') ||
      this.devMode ||
      (typeof window !== 'undefined' && window.storyblok)
    ) {
      // parameters.version = 'draft'; // FIX!!!
    }

    if (
      typeof window !== 'undefined' &&
      typeof window.StoryblokCacheVersion !== 'undefined'
    ) {
      parameters.cv = window.StoryblokCacheVersion;
    }

    return this.client.get(slug, parameters);
  }

  initEditor(context: any) {
    if (window.StoryblokBridge) {
      const storyblokInstance = new window.StoryblokBridge({
        resolveRelations: ['featured-articles.articles'],
      });

      // Use the input event for instant update of content
      storyblokInstance.on('input', (event) => {
        if (event?.story.content._uid === context.state.pageData._uid) {
          context.setState({
            pageData: event?.story.content,
          });
        }
      });

      // Use the bridge to listen the events
      storyblokInstance.on(['published', 'change'], () =>
        window.location.reload()
      );
    }
  }

  setQuery(query: string) {
    this.query = query;
  }

  getQuery(param: string) {
    return this.query.includes(param);
  }
}

const storyblokInstance = new StoryblokService();

export default storyblokInstance;
