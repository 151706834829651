import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer-ts';
import { ReactDynamicComponent } from '../common';
import { KeyPointItemStoryblok, KeyPointsStoryblok } from '../../types';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import './keyPoints.component.scss';

const SbKeyPoints: ReactDynamicComponent<KeyPointsStoryblok> = ({ blok }) => {
  const { template } = blok;
  const keyPointItem = (item: KeyPointItemStoryblok, index: number) => (
    <div key={index} className="point-items">
      {item.image && (
        <div>
          <AdaptiveImage
            src={item.image[0]?.image?.filename || ''}
            mobilesrc={item.image[0]?.mobileImage?.filename}
            tabletsrc={item.image[0]?.tabletImage?.filename}
            alt={
              item.image[0]?.image?.alt || item.image[0]?.mobileImage?.alt || ''
            }
            processing={item.image[0]?.processing}
            key={item.image[0]?._uid}
          />
          {item.text && <div>{render(item.text)}</div>}
        </div>
      )}
    </div>
  );
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div
        className={`keypoints ${
          template === 'keypoints' ? 'flex-col' : 'hidden'
        }`}
      >
        <div className="keypoints-title">{blok.title}</div>
        {blok.items &&
          blok.items.map((item: KeyPointItemStoryblok, index: number) =>
            keyPointItem(item, index)
          )}
      </div>
      <div className={`bullets ${template === 'bullets' ? 'flex' : 'hidden'}`}>
        <div className="bullets-container">
          <div className="bullets-description">{blok.description}</div>
          <div className="bullets-content">
            {blok.items &&
              blok.items.map((item: KeyPointItemStoryblok, index: number) =>
                keyPointItem(item, index)
              )}
          </div>
        </div>
      </div>
      <div
        className={`keypoints-gray ${
          template === 'keypoints-gray' ? 'flex flex-col' : 'hidden'
        }`}
      >
        <div className="keypoints-gray-title">{blok.title}</div>
        {blok.items &&
          blok.items.map((item: KeyPointItemStoryblok, index: number) =>
            keyPointItem(item, index)
          )}
      </div>
      <div
        className={`flip-flop ${
          template === 'flip-flop' ? 'flex-col' : 'hidden'
        }`}
      >
        <div className="flip-flop-description">{blok.description}</div>
        {blok.items &&
          blok.items.map((item: KeyPointItemStoryblok, index: number) =>
            keyPointItem(item, index)
          )}
      </div>
    </div>
  );
};
export default SbKeyPoints;
