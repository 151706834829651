import React, { useContext, useEffect, useState } from 'react';
import './recent-articles.component.scss';
import { ReactDynamicComponent } from '../common';
import { RecentArticlesStoryblok, PostStoryblok } from '../../types';
import BlogContext from '../blogContext';
import NoImage from '../../components/image/noImage';

import { generatedId } from '../../utils/uidGenerator';

const SbRecentArticles: ReactDynamicComponent<RecentArticlesStoryblok> = ({
  blok,
}) => {
  const { blogs, authors, author } = useContext(BlogContext);

  const {
    initialArticlesCount = 6,
    showMoreCount = 6,
    template = 'recentArticles',
  } = blok;

  const [articlesCount, setArticlesCount] = useState(initialArticlesCount);
  const [showButtonEnabled, setShowButtonEnabled] = useState(true);
  const [title, setTitle] = useState('Recent articles');
  const [filteredBlogs, setFilteredBlogs] = useState([{}]);
  const [currentTag, setCurrentTags] = useState<string[]>([]);

  const RenderTags = (tags: string[]) =>
    tags && tags?.length ? (
      <div className="recent-articles__article-tags">
        {tags.map((tag: string, index: number) => (
          <span key={String(index)}>{tag}</span>
        ))}
      </div>
    ) : null;

  const RenderArticle = (article: any) => {
    const content =
      article.content?.body &&
      (article.content?.body.filter(
        (item: any) => item.component === 'postItem'
      )[0] as PostStoryblok);

    // sometimes the field article.uuid is empty and a warning occurs in the console
    const articleId = article.uuid || generatedId();

    return (
      <article className="recent-articles__article" key={articleId}>
        <a href={`/${article.full_slug}/`}>
          <div className="recent-articles__article-image-wrapper">
            {content?.cover &&
            content?.cover?.length &&
            content?.cover[0]?.image.filename ? (
              <>
                <img
                  src={
                    content?.cover &&
                    content?.cover?.length &&
                    content?.cover[0]?.image.filename
                  }
                  alt={content?.cover[0]?.image.alt || ''}
                />
              </>
            ) : (
              <NoImage />
            )}
          </div>
          {RenderTags(article.tag_list)}
          <h3 className="recent-articles__article-title">{content?.title}</h3>
          <div className="recent-articles__article-medium-description">
            {content?.mediumDescription}
          </div>
          <div className="recent-articles__article-short-description">
            {content?.shortDescription}
          </div>
        </a>
        <footer className="recent-articles__article-footer">
          <div className="recent-articles__article-author">
            <a
              href={
                content?.author
                  ? `/${authors?.[content.author]?.full_slug}`
                  : '#'
              }
            >
              {content?.author ? authors[content.author].name : ''}
            </a>
          </div>
          {content?.readTime ? (
            <div className="recent-articles__article-duration">
              {content?.readTime} min read
            </div>
          ) : null}
        </footer>
      </article>
    );
  };

  const RenderArticles = () =>
    filteredBlogs
      .filter((item, index) => index < articlesCount)
      .map((item) => RenderArticle(item));

  const RenderShowMoreButton = () => {
    if (showButtonEnabled && articlesCount < filteredBlogs?.length) {
      return (
        <div className="recent-articles__show-more">
          <button
            type="button"
            onClick={() => {
              setArticlesCount(articlesCount + showMoreCount);
            }}
          >
            Show More
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    switch (template) {
      case 'moreByAuthor':
        setTitle(
          `More articles by ${
            author
              ? authors[author].content?.author[0]?.shortName ||
                authors[author].name
              : ''
          }`
        );
        setFilteredBlogs(
          blogs
            .filter((blog: any) => blog.content.body)
            .filter((blog: any) => {
              const post = blog.content.body.find(
                (a: any) => a.component === 'postItem'
              );
              return post && post.author === author;
            })
            .sort((a: any, b: any) =>
              a.first_published_at < b.first_published_at ? 1 : -1
            )
        );
        break;
      case 'moreOnTopic':
        setTitle('More articles on this topic');

        setShowButtonEnabled(false);

        setCurrentTags(() => {
          const currentTopic = blogs.filter(
            (blog) => blog.full_slug === window?.location.pathname.slice(1, -1)
          );
          return currentTopic && currentTopic?.length !== 0
            ? currentTopic[0]?.tag_list
            : [];
        });

        setFilteredBlogs(
          blogs
            .filter((blog: any) => blog.content.body)
            .filter(
              (blog) =>
                blog.full_slug !== window?.location.pathname.slice(1, -1)
            )
            .filter((blog) => {
              if (currentTag?.length !== 0) {
                for (let i = 0; i < blog.tag_list?.length; i + 1) {
                  if (currentTag.indexOf(blog.tag_list[i]) === -1) {
                    return false;
                  }

                  if (currentTag.indexOf(blog.tag_list[i]) !== -1) {
                    return true;
                  }
                }
              }

              return true;
            })
            .sort((a: any, b: any) =>
              a.first_published_at < b.first_published_at ? 1 : -1
            )
            .slice(0, 3)
        );
        break;
      default:
        setTitle('');
        setFilteredBlogs(
          blogs
            .filter((blog: any) => blog.content.body)
            .sort((a: any, b: any) =>
              a.first_published_at < b.first_published_at ? 1 : -1
            )
        );
        break;
    }
  }, [template, blogs]);

  return filteredBlogs?.length ? (
    <div className={`recent-articles template-${template}`}>
      <h2 className="recent-articles__title">{title}</h2>
      <div className="recent-articles__container">{RenderArticles()}</div>
      {RenderShowMoreButton()}
    </div>
  ) : null;
};

export default SbRecentArticles;
