import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { TeamStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';

import './team.component.scss';
import dynamicComponentsBlock from '../dynamicComponentsBlock';

const SbTeamComponent: ReactDynamicComponent<TeamStoryblok> = ({ blok }) => {
  const { title, label, items } = blok;

  return (
    <div
      className="team text-blue text-center mt-16 md:mt-32"
      {...storyblokEditable(blok)}
    >
      <h2 className="Poppins-24-36-Bold md:Poppins-32-44-Bold lg:Poppins-42-56-Bold">
        {title}
      </h2>
      <div className="mx-auto Poppins-14-20-Regular md:Poppins-16-24-Regular lg:Poppins-18-28-Regular w-8/12">
        {label}
      </div>
      <div className="flex flex-wrap justify-between lg:justify-start flex-row gap-y-16 mt-16 items-wrapper">
        {dynamicComponentsBlock(items)}
        {/* empty blocks for correct flex alignment */}
        <div className="team-item team-item__empty" />
        <div className="team-item team-item__empty" />
        <div className="team-item team-item__empty" />
      </div>
    </div>
  );
};

export default SbTeamComponent;
