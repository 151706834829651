import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { BenefitsItemStoryblok } from '../../types';

import './benefits-item.component.scss';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

const SbBenefitsItem: ReactDynamicComponent<BenefitsItemStoryblok> = ({
  blok,
}) => {
  const { title, label, image } = blok;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className="benefits-item">
        <div className="benefits-item__content">
          <h2 className="benefits-item__title">{title}</h2>
          <p className="benefits-item__label">{label}</p>
        </div>
        <div className="benefits-item__image">
          {image?.length
            ? image.map((img) => (
                <AdaptiveImage
                  src={img.image?.filename || ''}
                  mobilesrc={img.mobileImage?.filename}
                  alt={img.image?.alt || img.mobileImage?.alt || ''}
                  processing={img.processing}
                  key={img._uid}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default SbBenefitsItem;
