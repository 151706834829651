import React, { useEffect, useRef, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { ReactDynamicComponent } from '../common';
import { HeroBannerStoryblok } from '../../types';
import AdaptiveImage from '../../components/image/adaptiveImage.component';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import PlayBtn from '../../assets/images/play.svg';
import PauseBtn from '../../assets/images/pause.svg';

import './hero-banner.component.scss';

const SbHeroBanner: ReactDynamicComponent<HeroBannerStoryblok> = ({ blok }) => {
  const [isPlaying, setPlaying] = useState(false);

  const videoRef = useRef() as React.RefObject<HTMLVideoElement>;

  const { label, title, video, template, button, image } = blok;

  const onPlayHandler = () => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.play();
  };

  const onPauseHandler = () => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.pause();
  };

  const onVideoCheckStatus = () => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }
    setPlaying(!videoElement.paused);
  };

  const onVideoChangeStatus = () => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      return;
    }

    if (isPlaying) {
      onPauseHandler();
    } else {
      onPlayHandler();
    }
  };

  useEffect(() => {
    onVideoCheckStatus();
  }, [videoRef.current]);

  return (
    <div {...storyblokEditable(blok)} className="hero-banner">
      <div className="hero-banner__bg">
        <AdaptiveImage
          src={(image && image[0].image?.filename) || ''}
          mobilesrc={image && image[0].mobileImage?.filename}
          alt={(image && image[0].image?.alt) || ''}
          processing=""
        />
      </div>
      <div className="hero-banner__video">
        <video
          src={video?.filename}
          onPause={onVideoCheckStatus}
          onPlay={onVideoCheckStatus}
          onCanPlay={onPlayHandler}
          autoPlay
          muted
          loop
          playsInline
          ref={videoRef}
        />
      </div>
      <div
        className={` ${
          template === 'Build'
            ? 'hero-banner__holder-build'
            : 'hero-banner__holder'
        }`}
        onClick={onPauseHandler}
      >
        <div className="container">
          <div className="hero-banner__label">{label}</div>
          <h1
            className={` ${
              template === 'Build'
                ? 'hero-banner__title-build'
                : 'hero-banner__title'
            }`}
          >
            {title}
          </h1>
          {dynamicComponentsBlock(button)}
        </div>
      </div>
      <div
        className={`${isPlaying ? 'playing ' : 'paused '} ${
          template === 'Build' ? 'hidden' : ''
        } hero-banner__blur hero-banner__play hero-banner__center mobile-only`}
        onClick={onVideoChangeStatus}
      >
        {isPlaying ? <PauseBtn /> : <PlayBtn />}
      </div>
    </div>
  );
};

export default SbHeroBanner;
