import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { render } from 'storyblok-rich-text-react-renderer-ts';
import { ContactFormStoryblok } from '../../types';
import { ReactDynamicComponent } from '../common';
import './contactForm.component.scss';
import dynamicComponentsBlock from '../dynamicComponentsBlock';
import Loader from '../../assets/images/Loader2.svg';
import AtIcon from '../../assets/images/atIcon.svg';
import PhoneTube from '../../assets/images/phoneTube.svg';
import CrossClosure from '../../assets/images/crossClosure.svg';
import AdaptiveImage from '../../components/image/adaptiveImage.component';

enum Status {
  waiting,
  sending,
  success,
  error,
}

const SbContactForm: ReactDynamicComponent<ContactFormStoryblok> = ({
  blok,
}) => {
  const {
    fields,
    contactUsHeader,
    contactUsImage,
    contactUsDetails,
    contactUsBackground,
    successImage,
    // privacyText,
    // privacyUrl,
    submit,
    logo,
    successTitle,
  } = blok;

  const [status, setStatus] = useState(Status.waiting);

  const apiUrl =
    'https://pcoevb06v6.execute-api.us-west-1.amazonaws.com/send/send';
  const mailchimpUrl = 'https://tula.us14.list-manage.com/subscribe/post';

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    const formData = new FormData(target);
    setStatus(Status.sending);

    if (
      formData.has('newsletter') &&
      formData.get('newsletter') === 'on' &&
      !window.location.host.includes('www-qa.tula.co')
    ) {
      try {
        await Promise.all([
          fetch(mailchimpUrl, {
            method: 'POST',
            mode: 'cors',
            body: formData,
          }),
        ]);
      } catch (err: any) {
        console.error(err); // eslint-disable-line no-console
      }
    }

    try {
      await Promise.all([
        fetch(apiUrl, {
          method: 'POST',
          mode: 'cors',
          body: formData,
        }),
      ]);

      setStatus(Status.success);
    } catch (err: any) {
      setStatus(Status.error);
      console.error(err); // eslint-disable-line no-console
    }
  };

  const confirmHandler = () => {
    setStatus(Status.waiting);
  };

  const RenderForm = () => (
    <form onSubmit={submitHandler} id="contact_form" method="POST">
      <h1 className="contact-form__title Poppins-21-42-Bold lg:Poppins-42-56-Bold">
        Let’s discuss your project
      </h1>
      {dynamicComponentsBlock(fields)}

      {/* <div className="Poppins-12-16-Regular mb-8">
        {privacyText}
        &nbsp;
        <Link
          to={privacyUrl?.cached_url}
          className="underline hover:text-red-500 hover:underline cursor-pointer"
        >
          privacy policy
        </Link>
      </div> */}
      <div className="contact-form__submit Poppins-14-20-Medium lg:Poppins-16-24-SemiBold">
        {status === Status.sending ? (
          <div className="contact-form__spinner text-red-600">
            <Loader />
          </div>
        ) : (
          dynamicComponentsBlock(submit)
        )}
      </div>
      <input type="hidden" name="u" value="4434e9f1881dab02f1acff04c" />
      <input type="hidden" name="id" value="8b31a55ae9" />
    </form>
  );

  const RenderSuccessBlock = () => (
    <div className="contact-form__modal-wrapper">
      <div className="contact-form__success-block flex flex-col justify-center items-center">
        {successImage && successImage[0] && successImage[0].image && (
          <AdaptiveImage
            className="success-image"
            src={successImage[0].image.filename}
            alt={successImage[0].image.alt ?? ''}
            processing=""
          />
        )}
        <p className="Poppins-24-36-Bold my-4 text-center px-8 md:px-0">
          {successTitle}
        </p>
      </div>
    </div>
  );

  const RenderErrorBlock = () => (
    <div className="contact-form__modal-wrapper">
      <div className="contact-form__success-block contact-form__error-block flex flex-col justify-center items-center">
        <p className="Poppins-24-36-Bold my-4 text-red-500">
          Something went wrong...
        </p>
        <p className="Poppins-18-28-Regular">Let&apos;s try later</p>
        <button
          type="button"
          className="modal-button bg-red-600 hover:bg-red-700 text-white py-2 px-6 rounded mt-4"
          onClick={confirmHandler}
        >
          Close
        </button>
      </div>
    </div>
  );

  const RenderByStatus = () => {
    switch (status) {
      case Status.waiting:
        return RenderForm();
      case Status.success:
        return RenderSuccessBlock();
      case Status.error:
        return RenderErrorBlock();
      default:
        return RenderForm();
    }
  };
  // @ts-ignore
  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <div className="contact-form">
        {RenderByStatus()}
        <div className="contact-form__contacts-wrapper hidden lg:block rounded-tr-lg rounded-br-lg overflow-hidden">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          {contactUsBackground &&
            contactUsBackground[0] &&
            contactUsBackground[0].image && (
              <AdaptiveImage
                className="background-card"
                src={contactUsBackground[0].image.filename}
                alt={contactUsBackground[0].image.alt ?? ''}
                processing=""
              />
            )}
          <div className="logo-contacts">
            {logo && (
              <AdaptiveImage
                src={logo.filename}
                alt={logo.alt ?? ''}
                processing=""
              />
            )}
          </div>
          <a
            onClick={() => window.history.go(-1)}
            className="contact-form__close-window"
          >
            <div className="radial">
              <CrossClosure className="cross" />
            </div>
          </a>
          <div className="contact-form__contacts-card">
            <div className="contact-form__description Lora-24-32-Italic">
              {contactUsHeader}
            </div>
            <div className="contact-form__card-image">
              {contactUsImage &&
                contactUsImage[0] &&
                contactUsImage[0].image && (
                  <AdaptiveImage
                    src={contactUsImage[0].image.filename}
                    alt={contactUsImage[0].image.alt ?? ''}
                    processing={contactUsImage[0].processing}
                  />
                )}
            </div>
            <div className="contact-form__card-info">
              <div>{render(contactUsDetails)}</div>
            </div>
            <div className="contact-form__card-contacts">
              <a href={`tel:${blok.phone}`}>
                <PhoneTube className="mr-1" />
                {blok.phone}
              </a>{' '}
              <a href={`mailto:${blok.email}`}>
                <AtIcon className="mr-1" />
                {blok.email}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SbContactForm;
